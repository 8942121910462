import { useMediaQuery } from '@mantine/hooks';
import breakpoints from 'common/styles/breakpoint';
import React from 'react';

import { useStateIfMounted } from './use-effect-derivatives';

/*
https://github.com/mantinedev/mantine/blob/master/src/mantine-hooks/src/use-media-query/use-media-query.ts
useMediaQuery will attach a new listener for every hook call. Having a lot of children with useMediaQuery active might cause performance problems.

ResponsiveProvider will take in a mediaQuery and distributes it to children subscribed to it. If the media query evaluates to true, isSmallScreen will return true.
*/

interface ResponsiveProviderProps {
  mediaQuery?: string;
  disabled?: boolean;
  children: React.ReactNode;
}
interface ResizeWhenScreenProps {
  desktop: React.FC<void> | React.ReactNode;
  mobile: React.FC<void> | React.ReactNode;
}

export const SmallScreenContext = React.createContext(false);
export function useIsSmallScreen(preference?: boolean) {
  const value = React.useContext(SmallScreenContext);
  return preference || value;
}

export function SmallScreenProvider(props: ResponsiveProviderProps) {
  const { mediaQuery = breakpoints.screenMaxLg, disabled, children } = props;
  const isMobile = useMediaQuery(
    mediaQuery,
    typeof window !== 'undefined'
      ? window.matchMedia(breakpoints.screenMaxLg).matches
      : true,
  );

  // This is necessary to prevent hydration errors and flickering. This is because we try to fetch media query status (in useIsSmallScreen) right when the component is created in the browser. But in the server, there's no media to query which means both values in the browser and the server will differ, causing this component to render different DOM trees, and so: hydration error.
  // It makes server-side rendering pretty much useless, but at least there's no flickering or hydration error.
  const [renderChild] = useStateIfMounted({
    initializer: () => true,
  });
  if (!renderChild) {
    return null;
  }
  return (
    <SmallScreenContext.Provider value={!disabled && !!isMobile}>
      {children}
    </SmallScreenContext.Provider>
  );
}

export function ResizeWhenScreen(props: ResizeWhenScreenProps) {
  const small = useIsSmallScreen();
  if (small) {
    return typeof props.mobile === 'function' ? props.mobile() : props.mobile;
  } else {
    return typeof props.desktop === 'function'
      ? props.desktop()
      : props.desktop;
  }
}
