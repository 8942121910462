import { ClassConstructor, plainToInstance } from 'class-transformer';
import { client } from 'common/api/ky-client';
import { PaginationMeta, Filter, Sort } from 'common/api/model';
import { toApiError, isNetworkError, blobToBase64 } from 'common/api/utils';
import { decamelizeKeys } from 'humps';
import type { Options, default as ky } from 'ky';
import qs from 'qs';

type MutationMethodType = 'GET' | 'POST' | 'PUT' | 'PATCH' | 'DELETE';
interface FetchMutationOptions<T> {
  url: string;
  method: MutationMethodType;
  body?: any;
  classType?: ClassConstructor<T>;
  params?: any;
  client?: ReturnType<typeof ky.create>;
  header?: Options['headers'];
}

const BLOB_TYPES = [
  'application/pdf',
  'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
];

export function MutationFetchFunction<T>({
  url,
  method,
  body,
  classType,
  params,
  client: altClient,
  header,
}: FetchMutationOptions<T>): Promise<any> {
  return new Promise(async (resolve, reject) => {
    const apiBody = body ? decamelizeKeys(body) : undefined;
    const apiParams = params ? qs.stringify(params) : '';
    const options: Options = { method };
    if (apiParams) {
      options.searchParams = apiParams;
    }
    if (apiBody) {
      options.json = apiBody;
    }
    if (header) {
      options.headers = header;
    }

    const usedClient = altClient ?? client;
    try {
      const response = await usedClient(url, options);
      const contentType = response.headers.get('content-type');

      if (contentType && BLOB_TYPES.includes(contentType)) {
        const blobData = await blobToBase64(await response.blob());
        resolve(blobData);
        return;
      }

      if (response.status === 204) {
        // No response
        resolve(undefined);
      }

      const json = (await response.json()) as any;
      if (classType) {
        json.data = plainToInstance(classType, json.data);
      }

      resolve(json);
    } catch (e) {
      reject(await toApiError(e as Error));
    }
  });
}
interface QueryFetchOptions {
  url: string;
  params?: any;
  /** If cache is true, then the response will be stored with the url and params as key. If cache is a string, the string will be used as cache key. */
  cache?:
    | string
    | boolean
    | {
        set(value: any): void;
        get(): any;
      };
  options?: Options;
  client?: ReturnType<typeof ky.create>;
}

export function QueryFetchFunction({
  url,
  params,
  options,
  cache,
  client: altClient,
}: QueryFetchOptions): Promise<any> {
  return new Promise(async (resolve, reject) => {
    const _params = params ? qs.stringify(params) : '';
    const cacheKey =
      typeof cache === 'string'
        ? cache
        : `${url}${_params ? `?${_params}` : ''}`;

    const usedClient = altClient ?? client;
    try {
      const json: any = await usedClient
        .get(url, {
          searchParams: _params || undefined,
          ...options,
        })
        .json();

      if (cache) {
        if (typeof cache === 'object' && cache.set) {
          cache.set(json);
        } else {
          localStorage.setItem(cacheKey, JSON.stringify(json));
        }
      }
      resolve(json);
    } catch (e: any) {
      console.error(e);
      if (!isNetworkError(e)) {
        reject(await toApiError(e));
        return;
      }

      let cached: string | null = null;
      if (typeof cache === 'object' && cache.get) {
        cached = cache.get();
      } else {
        cached = localStorage.getItem(cacheKey);
      }

      if (cached) {
        resolve(JSON.parse(cached));
      } else {
        reject(e);
      }
    }
  });
}

function __QueryTransformData(res, dataType) {
  return {
    ...res,
    data: dataType ? plainToInstance(dataType, res.data) : res.data,
    ...(res.meta ? { meta: plainToInstance(PaginationMeta, res.meta) } : {}),
    ...(res.sorts
      ? {
          sorts: plainToInstance(Sort, res.sorts),
        }
      : {}),
    ...(res.filters
      ? {
          filters: plainToInstance(Filter, res.filters),
        }
      : {}),
  };
}

export function QueryTransformer(res: any, dataType?: any) {
  const { data: json } = res;

  if (json === undefined) {
    return res;
  }
  let newJson: any;
  if (json.pages) {
    newJson = {
      ...json,
      ...(json.pages
        ? {
            pages:
              dataType && !!json?.pages.length
                ? json.pages.map((page) => {
                    return __QueryTransformData(page, dataType);
                  })
                : json.pages,
          }
        : {}),
    };
  } else {
    newJson = __QueryTransformData(json, dataType);
  }

  return {
    ...res,
    data: newJson,
  };
}
