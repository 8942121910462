import React from 'react';

export function useIsMounted(rerender?: boolean) {
  const isMounted = React.useRef(false);
  const [, setMounted] = React.useState(false);
  React.useEffect(() => {
    isMounted.current = true;
    if (rerender) {
      setMounted(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return isMounted.current;
}

export enum ParentRefType {
  Loading = 'loading',
  Scroll = 'scroll',
  Footer = 'footer',
}

type ParentRefContextType = Partial<
  Record<ParentRefType, React.MutableRefObject<HTMLElement | null>>
>;
const ParentRefContext = React.createContext<ParentRefContextType>({});

export function ParentRefProvider(props: { children?: React.ReactNode }) {
  const scrollRef = React.useRef<HTMLElement | null>(null);
  const loadingRef = React.useRef<HTMLElement | null>(null);
  const footerRef = React.useRef<HTMLElement | null>(null);

  return (
    <ParentRefContext.Provider
      value={{
        [ParentRefType.Loading]: loadingRef,
        [ParentRefType.Scroll]: scrollRef,
        [ParentRefType.Footer]: footerRef,
      }}
    >
      {props.children}
    </ParentRefContext.Provider>
  );
}

export default function useGetParentRef(
  type: ParentRefType,
): React.MutableRefObject<HTMLElement | null> {
  const empty = React.useRef(null);
  return React.useContext(ParentRefContext)[type] ?? empty;
}
