import { useMutation, useQuery, UseQueryOptions } from '@tanstack/react-query';
import { MutationFetchFunction, QueryFetchFunction } from 'common/api/fetch';
import { ApiMutationFunction } from 'common/api/fetch-types';
import { ApiError, ApiResult } from 'common/api/model';
import { maybeElement } from 'common/utils/iterable';

import { EnumResultModel, getEnumsInput } from './model';

export const StaleTimes = {
  Long: 15 * 60 * 1000,
  Medium: 5 * 60 * 1000,
  Short: 1 * 60 * 1000,
};

export function getEnumsKey(enumClass?: string) {
  return ['getEnums', ...maybeElement(!!enumClass, enumClass)];
}

export function useGetEnums(
  input?: getEnumsInput,
  options?: UseQueryOptions<ApiResult<EnumResultModel[]>, ApiError>,
) {
  return useQuery<ApiResult<EnumResultModel[]>, ApiError>(
    options?.queryKey || getEnumsKey(input?.class),
    () =>
      QueryFetchFunction({
        url: `enums/${input?.class}`,
      }),
    options,
  );
}

export function useGetHealth(
  input?: any,
  options?: UseQueryOptions<{ status: string }, ApiError>,
) {
  return useQuery<{ status: string }, ApiError>(
    ['getHealth', input?.class],
    () =>
      QueryFetchFunction({
        url: `health`,
      }),
    options,
  );
}

export const useValidatePhone: ApiMutationFunction<
  {
    phoneNumber: string;
  },
  ApiResult<never>
> = (options) => {
  return useMutation((body) => {
    return MutationFetchFunction({
      url: 'phone/validate-mobile',
      method: 'POST',
      body,
    });
  }, options);
};
