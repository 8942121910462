export enum LocalStorageKeys {
  // Recent search history in search screen
  RecentSearchHistoryPrefix = 'recent-search-history--',
  // Which referral code is currently being viewed by Affiliate
  MainReferralId = 'main-referral-id',
  // Cache key for offline esim access
  EsimCache = 'my-sim',

  HttpCacheToken = 'http-cache-token',

  AuthStorage = 'kurosim-auth.session-token',
  HasShownOnboarding = 'has_shown_onboarding',

  Language = 'kurosim-language',
  MyData = 'me-data',
}

export enum SessionStorageKeys {
  // Session ID for anonymous users in Crisp chat
  CrispAnonymousSessionId = 'crisp-anonymous-session-id',
  // Safe area parameter
  SafeArea = 'safe-area',
  UserAgent = 'user-agent',
  StackNavigation = 'stack_navigation_kurosim',
  TabNavigation = 'tab_navigation_kurosim',
}
