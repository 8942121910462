export enum AssetPaths {
  // Brands
  AppIcon = '/assets/brand/app-icon.png',
  IconLogoDark = '/assets/brand/icon-logo-dark.png',
  IconLogoLight = '/assets/brand/icon-logo-light.png',
  PrimaryLogoDark = '/assets/brand/primary-logo-dark.png',
  PrimaryLogoLight = '/assets/brand/primary-logo-light.png',
  PrimaryLogoBlack = '/assets/brand/primary-logo-black.svg',

  // File
  FileExcel = '/assets/file/file-excel.png',
  FilePdf = '/assets/file/file-pdf.png',
  FileWord = '/assets/file/file-word.png',
  FileGlobal = '/assets/file/global-file.png',

  // Maps
  MapAsia = '/assets/maps/asia.png',
  MapEurope = '/assets/maps/europe.png',
  MapGlobal = '/assets/maps/global.png',
  MapLatinAmerica = '/assets/maps/latin-america.png',
  MapNorthAmerica = '/assets/maps/north-america.png',

  // Misc
  AffiliateStatus = '/assets/misc/affiliate-status.svg',
  ColorfulDesktopTint = '/assets/misc/colorful-desktop-tint.png',
  GiftBox = '/assets/misc/gift-box.png',
  ProfileKuroPointBackground = '/assets/misc/profile-kuro-point-background.png',
  Speaker = '/assets/misc/speaker.png',
  ColorfulBackground = '/assets/misc/colorful-background.png',
  QrisPaymentBackground = '/assets/misc/qris-payment-background.png',
  /** Unused for now. This was used in use-qr, but the URL was later changed to an inline base64 url for a resized image of size 100 x 100. */
  QrLogo = '/assets/misc/qr-logo.png',
  PaymentCheck = '/assets/misc/payment-check.png',
  DeviceMobile = '/assets/misc/device-mobile.svg',
  DeviceDesktop = '/assets/misc/device-desktop.svg',

  // Onboarding
  OnboardingBackgroundFirst = '/assets/onboarding/background-first.webp',
  OnboardingBackgroundSecond = '/assets/onboarding/background-second.webp',
  OnboardingFirst = '/assets/onboarding/onboarding-first.webp',
  OnboardingFourth = '/assets/onboarding/onboarding-fourth.webp',
  OnboardingSecond = '/assets/onboarding/onboarding-second.webp',
  OnboardingThird = '/assets/onboarding/onboarding-third.webp',

  // Payment Method
  XenditPaymentMethods = '/assets/payment-method',
  ProtectedCardInfo = '/assets/payment-method/protected-card-info.svg',
  ClickPay = '/assets/payment-method/Click Pay.png',
  CardVISA = '/assets/payment-method/VISA.png',
  CardMasterCard = '/assets/payment-method/master-card.svg',
  CardJCB = '/assets/payment-method/JCB.png',
  CardAMEX = '/assets/payment-method/AMEX.png',
  CardGPN = '/assets/payment-method/GPN.png',
  CVVRequirement = '/assets/payment-method/cvv-requirement.svg',
  PaymentMethodQris = '/assets/payment-method/qris.png',

  // Regions
  RegionAfrica = '/assets/region/Africa.png',
  RegionAsia = '/assets/region/Asia.png',
  RegionCaribbeanIslands = '/assets/region/CaribbeanIslands.png',
  RegionEurope = '/assets/region/Europe.png',
  RegionGlobal = '/assets/region/Global.png',
  RegionLatinAmerica = '/assets/region/LatinAmerica.png',
  RegionMENA = '/assets/region/MENA.png',
  RegionMiddleEast = '/assets/region/MiddleEast.png',
  RegionNorthAmerica = '/assets/region/NorthAmerica.png',
  RegionOceania = '/assets/region/Oceania.png',
  RegionSoutheastAsia = '/assets/region/SoutheastAsia.png',

  // Sim Skin
  SimSkins = '/assets/sim-skin',
  SimSkinBlueAbstract = '/assets/sim-skin/blue_abstract.png',
  SimSkinBlue = '/assets/sim-skin/blue.png',
  SimSkinDark = '/assets/sim-skin/dark.png',
  SimSkinGreen = '/assets/sim-skin/green.png',
  SimSkinKPattern = '/assets/sim-skin/k_pattern.png',
  SimSkinOrange = '/assets/sim-skin/orange.png',

  // Social Media
  SocialMedia = '/assets/social-media',
  SocialMediaFacebookMessenger = '/assets/social-media/facebook_messenger.png',
  SocialMediaFacebook = '/assets/social-media/facebook.png',
  SocialMediaGab = '/assets/social-media/gab.png',
  SocialMediaHatena = '/assets/social-media/hatena.png',
  SocialMediaInstagramGrey = '/assets/social-media/instagram-grey.svg',
  SocialMediaInstagram = '/assets/social-media/instagram.png',
  SocialMediaInstapaper = '/assets/social-media/instapaper.png',
  SocialMediaLINE = '/assets/social-media/line.png',
  SocialMediaLinkedIn = '/assets/social-media/linkedin.png',
  SocialMediaLiveJ = '/assets/social-media/livej.png',
  SocialMediaMail = '/assets/social-media/mail.png',
  SocialMediaMailRu = '/assets/social-media/mail.ru.png',
  SocialMediaOdnoklassniki = '/assets/social-media/odnoklassniki.png',
  SocialMediaPocket = '/assets/social-media/pocket.png',
  SocialMediaReddit = '/assets/social-media/reddit.png',
  SocialMediaTelegram = '/assets/social-media/telegram.png',
  SocialMediaTiktokGrey = '/assets/social-media/tiktok-grey.svg',
  SocialMediaTiktok = '/assets/social-media/tiktok.png',
  SocialMediaTumblr = '/assets/social-media/tumblr.png',
  SocialMediaViber = '/assets/social-media/viber.png',
  SocialMediaVK = '/assets/social-media/VK.png',
  SocialMediaWeibo = '/assets/social-media/weibo.png',
  SocialMediaWhatsApp = '/assets/social-media/whatsapp.png',
  SocialMediaWorkplace = '/assets/social-media/workplace.png',
  SocialMediaX = '/assets/social-media/X.png',
  SocialMediaYouTubeGrey = '/assets/social-media/youtube-grey.svg',

  // State
  PageNotFound = '/assets/state/404.png',
  CheckEsim = '/assets/state/check-esim.png',
  ConnectionError = '/assets/state/connection-error.png',
  ClientNotFoundError = '/assets/state/client-not-found-error.png',
  ServerError = '/assets/state/server-error.png',
  Empty = '/assets/state/empty-box.png',
  eSIMInstall = '/assets/state/install-eSIM.png',
  EmptyGiftbox = '/assets/state/empty-giftbox.png',
  Hourglass = '/assets/state/hourglass.png',
  NoData = '/assets/state/no-data.png',
  NoData2 = '/assets/state/no-data2.png',
  NoEsim = '/assets/state/no-esim.png',
  UninstalledEsim = '/assets/state/uninstalled-esim.png',
  EsimDied = '/assets/state/esim-died.png',
  Warning = '/assets/state/warning.png',
  RechargeFailed = '/assets/state/recharge-failed.svg',

  Fallback = '/assets/state/fallback.svg',

  // Flags
  Flags = '/assets/webp',

  // Sim
  SimInstall = '/assets/sim/sim-install.png',

  //Add To Home
  SafariFirst = '/assets/add-home/safari-1.png',
  SafariSecond = '/assets/add-home/safari-2.png',
  SafariThird = '/assets/add-home/safari-3.png',
  ChromeFirst = '/assets/add-home/chrome-1.png',
  ChromeSecond = '/assets/add-home/chrome-2.png',
  ChromeThird = '/assets/add-home/chrome-3.png',
}
