import ky, { Options } from 'ky';

import { KY_BASE_CONFIG } from './constants';
import { KyClientHooks } from './ky-hooks';

const KY_DEFAULT_CONFIG = {
  ...KY_BASE_CONFIG,
  // 10/08/2024: This shouldn't be that useful now that we have KyClientHooks.setupAuthorization to fetch refresh tokens.
  // retry: {
  //   limit: 2,
  //   methods: ['get', 'post', 'put', 'patch', 'delete'],
  //   statusCodes: [401],
  // },
  hooks: {
    // 24/06/2024: Removed afterResponse hook because it literally does nothing (...hopefully) to the response (the actual values still need to be fetched via .json() and .blob() from the response). QueryFetchFunction and MutationFetchFunction already handles the JSON parsing and blobToBase64 conversion.
    afterResponse: [KyClientHooks.onUnauthenticated],
    beforeRetry: [KyClientHooks.xRetryHeader],
    beforeRequest: [KyClientHooks.setupAuthorization],
  },
} as Options;

/*
We use separate clients for auth/refresh, me, and the other endpoints; because each of them requires different behaviors compared to the default KY-client. auth/refresh doesn't need KyClientHooks.setupAuthorization, and me doesn't need KyClientHooks.onUnauthenticated or else it'll cause infinite loop. Technically, we can just check the URL and adjust the behavior accordingly, but that doesn't seem clean since it mixes the responsibility layers.

Asynchronous state layer: Query Client - should only know how to cache data and maintain asynchronous state; doesn't care how the data is fetched or how it will be consumed later.
Fetching/Network layer: Ky Client - should only know about how to fetch and consume authentication data.
API layer: useQuery, useMutation - should know the endpoints and the expected data, doesn't care how they are fetched.

If we define the endpoints for auth/refresh and me in ky-client. That means the fetching/network layer now takes partial responsibility for the API layer. It's already bad enough that the Asynchronous State layer (query client) controls one of the query keys (GET_ME_KEY), so I'd like to reduce the number of responsibility mixing.

Consider refining this in the future if you have any new ideas.
*/
export const client = ky.create(KY_DEFAULT_CONFIG);
export const meClient = ky.create({
  ...KY_BASE_CONFIG,
  hooks: {
    beforeRetry: [KyClientHooks.xRetryHeader],
    beforeRequest: [KyClientHooks.setupAuthorization],
  },
});
