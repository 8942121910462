import { MinimalModel } from 'api-hooks/common';
import { Expose, Type } from 'class-transformer';

export class OauthAccountModel {
  @Expose({ name: 'access_token' })
  accessToken: string;

  @Expose({ name: 'expires_at' })
  expiresAt: number;

  @Expose({ name: 'id_token' })
  idToken: string;

  provider: string;
  providerAccountId: string;
  scope: string;

  @Expose({ name: 'token_type' })
  tokenType: string;

  type: string;
}

export class OauthProfileModel {
  @Expose({ name: 'at_hash' })
  atHash: string;

  aud: string;
  azp: string;
  email: string;

  @Expose({ name: 'email_verified' })
  emailVerified: string;

  exp: number;

  @Expose({ name: 'family_name' })
  familyName: string;

  @Expose({ name: 'given_name' })
  givenName: string;

  iat: number;
  iss: string;
  locale: string;
  name: string;
  picture: string;
  sub: string;
}

export class OauthUserModel extends MinimalModel {
  email: string;
  image: string;
}

export class OauthModel {
  @Type(() => OauthAccountModel)
  account: OauthAccountModel;

  email: string;
  exp: number;
  iat: number;
  jti: string;
  name: string;
  picture: string;

  @Type(() => OauthProfileModel)
  profile: OauthProfileModel;

  sub: string;
  trigger: string;

  @Type(() => OauthUserModel)
  user: OauthUserModel;
}
