import exportEnv from 'common/config';

export const EXTERNAL_LINK = {
  TIKTOK_KUROSIM: 'https://www.tiktok.com/@kurosim.official',
  INSTAGRAM_KUROSIM: 'https://www.instagram.com/kurosim.official',
  YOUTUBE_KUROSIM: 'https://www.youtube.com/@kurosim.official',
  WHATSAPP_WEB_KUROSIM: (message = 'Hi+Kurosim') =>
    `https://api.whatsapp.com/send?phone=${exportEnv.kurosimPhoneNumber}&text=${message}`,
  WHATSAPP_APP_KUROSIM: (message = 'Hi+Kurosim') =>
    `whatsapp://send?phone=${exportEnv.kurosimPhoneNumber}&text=${message}`,
} as const;
