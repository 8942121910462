import { ActionIcon, ActionIconProps, Loader } from '@mantine/core';
import { Promisable } from 'common/utils/types';
import * as React from 'react';

import Button from './button';

export type PromiseButtonProps = Omit<
  React.ComponentProps<typeof Button>,
  'onClick' | 'loading'
> & {
  onClick?: () => Promisable<any>;
};

export type PromiseActionIconProps = Omit<
  ActionIconProps,
  'onClick' | 'loading'
> & {
  loaderProps?: React.ComponentProps<typeof Loader>;
  onClick?: () => Promisable<any>;
};

export function useAsyncInteract(
  onInteract: (() => void) | undefined,
  onError?: (err: unknown) => void,
) {
  const [isLoading, setLoading] = React.useState(false);
  const interact = React.useCallback(async () => {
    if (!onInteract) return;
    setLoading(true);
    try {
      await onInteract();
    } catch (e) {
      console.error(e);
      onError?.(e);
    } finally {
      setLoading(false);
    }
  }, [onInteract, onError]);
  return {
    isLoading,
    interact,
  };
}

export default function PromiseButton(props: PromiseButtonProps) {
  const { onClick, ...rest } = props;
  const { isLoading, interact } = useAsyncInteract(onClick);

  return <Button {...rest} onClick={interact} loading={isLoading} />;
}

export function PromiseActionIcon(props: PromiseActionIconProps) {
  const { onClick, loaderProps, ...rest } = props;
  const { isLoading, interact } = useAsyncInteract(onClick);
  return isLoading ? (
    <Loader {...loaderProps} />
  ) : (
    <ActionIcon
      style={{ display: 'flex', ...rest.style }}
      loading={isLoading}
      onClick={interact}
      {...rest}
    />
  );
}
