module.exports = {
  locales: ['id', 'en'],
  defaultLocale: 'en',
  loader: false,
  pages: {
    '*': [
      'common',
      'home',
      'sim',
      'auth',
      'profile',
      'error',
      'enum',
      'referral',
      'title',
      'transaction',
    ],
    '/example': ['example'],
  },
};
