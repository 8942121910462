export default function CellRangeIcon(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M10.2979 7.88672C9.26232 7.88672 8.42285 8.72618 8.42285 9.76172C8.42285 10.7973 9.26232 11.6367 10.2979 11.6367C11.3334 11.6367 12.1729 10.7973 12.1729 9.76172C12.1729 8.72618 11.3334 7.88672 10.2979 7.88672ZM7.17285 9.76172C7.17285 8.03583 8.57196 6.63672 10.2979 6.63672C12.0237 6.63672 13.4229 8.03583 13.4229 9.76172C13.4229 11.4876 12.0237 12.8867 10.2979 12.8867C8.57196 12.8867 7.17285 11.4876 7.17285 9.76172Z"
        fill="#989FA3"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M14.0737 5.54619C14.3308 5.31588 14.726 5.3376 14.9563 5.59471C15.9823 6.74016 16.5497 8.22391 16.5497 9.76172C16.5497 11.2995 15.9823 12.7833 14.9563 13.9287C14.726 14.1858 14.3308 14.2076 14.0737 13.9773C13.8166 13.7469 13.7949 13.3518 14.0252 13.0947C14.8459 12.1785 15.2997 10.9917 15.2997 9.76172C15.2997 8.53171 14.8459 7.34493 14.0252 6.42874C13.7949 6.17163 13.8166 5.7765 14.0737 5.54619Z"
        fill="#989FA3"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M6.52189 5.54619C6.779 5.7765 6.80072 6.17163 6.57041 6.42874C5.74971 7.34493 5.2959 8.53171 5.2959 9.76172C5.2959 10.9917 5.74971 12.1785 6.57041 13.0947C6.80072 13.3518 6.779 13.7469 6.52189 13.9773C6.26478 14.2076 5.86965 14.1858 5.63934 13.9287C4.61327 12.7833 4.0459 11.2995 4.0459 9.76172C4.0459 8.22391 4.61327 6.74016 5.63934 5.59471C5.86965 5.3376 6.26478 5.31588 6.52189 5.54619Z"
        fill="#989FA3"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M16.11 3.19171C16.3563 2.94988 16.752 2.95353 16.9938 3.19984C18.7131 4.95102 19.6763 7.30706 19.6763 9.76113C19.6763 12.2152 18.7131 14.5712 16.9938 16.3224C16.752 16.5687 16.3563 16.5724 16.11 16.3306C15.8637 16.0887 15.86 15.693 16.1019 15.4467C17.5917 13.9293 18.4263 11.8877 18.4263 9.76113C18.4263 7.63459 17.5917 5.59301 16.1019 4.07555C15.86 3.82924 15.8637 3.43353 16.11 3.19171Z"
        fill="black"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M4.48577 3.19171C4.73208 3.43353 4.73572 3.82924 4.4939 4.07555C3.0041 5.59301 2.16943 7.63459 2.16943 9.76113C2.16943 11.8877 3.0041 13.9293 4.4939 15.4467C4.73572 15.693 4.73208 16.0887 4.48577 16.3306C4.23945 16.5724 3.84374 16.5687 3.60192 16.3224C1.88266 14.5712 0.919434 12.2152 0.919434 9.76113C0.919434 7.30706 1.88266 4.95102 3.60192 3.19984C3.84374 2.95353 4.23945 2.94988 4.48577 3.19171Z"
        fill="black"
      />
    </svg>
  );
}
