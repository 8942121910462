import { OauthModel } from 'api-hooks/oauth/model';
import { plainToClass } from 'class-transformer';
import { ApiResult } from 'common/api/model';
import notification from 'common/helpers/notification';
import { SessionContextValue, useSession } from 'next-auth/react';
import React from 'react';

import useOauthSession from './use-oauth-session';

export type SessionType = ApiResult<OauthModel> | null;

export type CustomSessionType = Omit<SessionContextValue, 'data'> & {
  data: SessionType;
};

// this useOauthSessionWeb is control oauth on web platform
export default function useOauthSessionWeb() {
  const { data } = useSession() as CustomSessionType;

  const { onGetToken } = useOauthSession();

  const transform = React.useCallback((session: SessionType) => {
    if (!session?.data) return null;
    return plainToClass(OauthModel, session?.data) as never as OauthModel;
  }, []);

  const session = transform(data);

  const generator = React.useCallback((session: OauthModel | null) => {
    const token = session?.account;
    const idToken = token?.idToken;
    const provider = token?.provider;
    return {
      token,
      idToken,
      provider,
    };
  }, []);

  const { idToken, provider, token } = generator(session);

  const isSessionExpired = React.useCallback(() => {
    if (!token?.expiresAt) return true;
    const expiredAt = new Date(token.expiresAt * 1000);

    return new Date().getTime() > expiredAt.getTime();
  }, [token?.expiresAt]);

  const onGetSessionToken = React.useCallback(async () => {
    try {
      if (!idToken || !provider) return;

      const result = await onGetToken({
        idToken,
        provider,
        familyName: session?.profile?.familyName || null,
        givenName: session?.profile?.givenName || null,
      });

      result.message &&
        notification.success({
          message: result.message,
        });
    } catch (e) {
      console.error(e);
      notification.error({
        message: e.message,
      });
    }
  }, [
    idToken,
    onGetToken,
    provider,
    session?.profile?.familyName,
    session?.profile?.givenName,
  ]);

  return {
    session,
    idToken,
    provider,
    isSessionExpired,
    onGetSessionToken,
  };
}
