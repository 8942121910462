import { LocalStorageKeys } from 'common/constants/browser-storage-keys';
import { sendNativeMessage } from 'common/routes/bridge';
import { BridgeMessageType } from 'common/routes/bridge-types';
import { minutesToMiliseconds } from 'common/utils/date';
import { JwtPayload, jwtDecode } from 'jwt-decode';
import throttle from 'lodash/throttle';

import { TokenResultModel, mutateRefreshToken } from './api';

export namespace SessionToken {
  export function get(): TokenResultModel | undefined {
    if (typeof window === 'undefined') return undefined;
    const response = localStorage.getItem(LocalStorageKeys.AuthStorage);
    if (response === null) return undefined;
    const token = JSON.parse(response) as TokenResultModel;
    return token;
  }

  export function set(token: TokenResultModel | undefined) {
    if (typeof window === 'undefined') return;
    if (token === undefined) {
      localStorage.removeItem(LocalStorageKeys.AuthStorage);
    } else {
      const value = JSON.stringify(token);
      localStorage.setItem(LocalStorageKeys.AuthStorage, value);
    }

    sendNativeMessage({
      data: token ?? null,
      type: BridgeMessageType.SyncToken,
    });
  }

  export function clear() {
    if (typeof window === 'undefined') return;
    localStorage.removeItem(LocalStorageKeys.AuthStorage);
    sendNativeMessage({
      data: null,
      type: BridgeMessageType.SyncToken,
    });
  }

  export function isAccessTokenExpired(token: string, toleranceMinutes = 20) {
    if (!token) return true;

    const decoded = jwtDecode(token) as JwtPayload;
    if (!decoded.exp) return true;

    const expiredAt = new Date(decoded.exp * 1000);
    const currentAt = new Date();
    const tolerance = minutesToMiliseconds(toleranceMinutes);

    return currentAt.getTime() > expiredAt.getTime() - tolerance;
  }

  export function checkTokenExpiration(token: TokenResultModel | undefined): {
    isAccessTokenExpired: boolean;
    isRefreshTokenExpired: boolean;
  } {
    const refreshTokenExpiresAt = token
      ? new Date(token.refreshTokenExpiresAt)
      : undefined;
    return {
      isAccessTokenExpired: token
        ? isAccessTokenExpired(token.accessToken)
        : false,
      isRefreshTokenExpired:
        refreshTokenExpiresAt && !isNaN(refreshTokenExpiresAt.getTime())
          ? refreshTokenExpiresAt.getTime() < Date.now()
          : false,
    };
  }
}

/** Throttle is required so that we don't spam the refresh endpoint. Since ``tokenApi`` is called by MutationFetchFunction - which can call ``mutateRefreshToken`` is the token has expired - there's a high possibility that multiple requests will be made to the refresh endpoint at the same time.
 * This is moved here to avoid circular imports
 */
export const throttleMutateRefreshToken = throttle(mutateRefreshToken, 10000);

/**
 * if token is expired function will fire refresh token api and return new token and otherwise function will return current token
 */
export async function tokenApi(): Promise<TokenResultModel | undefined> {
  try {
    const token = SessionToken.get();
    if (!token) {
      return undefined;
    }

    const { isRefreshTokenExpired, isAccessTokenExpired } =
      SessionToken.checkTokenExpiration(token);
    // Refresh token is expired; user has to log in again. This is exactly a logout action though, since the user should be allowed to resume their activities once they have logged in again.
    if (isRefreshTokenExpired) {
      SessionToken.clear();
      return undefined;
    }

    // If it's just their access token being expired, we'll just fetch the new token
    if (isAccessTokenExpired) {
      const result = await throttleMutateRefreshToken({
        refreshToken: token.refreshToken,
      })!;
      SessionToken.set(result.data);
      return result.data;
    }

    return token;
  } catch (e) {
    console.error(e);
    return undefined;
  }
}
