import { SessionStorageKeys } from 'common/constants/browser-storage-keys';
import { NavigationRoutes } from 'common/routes';
import { uniqueByKeepLast } from 'common/utils/iterable';

import type { RouteHistoryEntry } from './types';

const windowInitiated = typeof window !== 'undefined';

/**
 * Just a namespace, don't instantiate
 * Wrapper to access sessionStorage information about the current navigation stack. Entries are stored in the following format:
 * Profile -> Edit Account -> Cart would become [{pathname: "profile"}, {pathname: "edit-account"}, {pathname: "cart"}].
 * Popping would set the stack to [{pathname: "profile"}, {pathname: "edit-account"}]. So the current route is always the last entry on the stack.
 *
 * Note that StackNavigation only keeps track of the history and does not do anything to perform the navigation. If you want to set the current route to the latest entry, consider using ``useKurosimNavigation().refresh``
 */
export class StackNavigation {
  /**
   * Stores all temporary stack items that will be used in ``unpop`` in a LIFO structure. This buffer will be cleared the moment the stack changes through other means besides ``pop``.
   * In other words, you can press the forward button if you've returned to the previous routes, in order to go back to the 'future routes'. But, once you've mutated the stack in some way like going to a new page or tab, the 'timeline' has already diverged and the 'future routes' can no longer be accessed.
   *
   * State #1:
   * A -> B -> C | _buffer = []
   * State #2 (Pop):
   * A -> B | _buffer = C
   * State #3 (Pop):
   * A | _buffer = C -> B
   * State #4 (Unpop):
   * A -> B | _buffer = C (FIFO behavior)
   * State #5 (Push):
   * A -> B -> D | _buffer = [] (buffer is cleared)
   * State #6 (Unpop):
   * A -> B -> D | _buffer = [] (nothing to unpop)
   */
  static _buffer: RouteHistoryEntry[] = [];

  /**
   * Gets the current stack state. This returns an empty array if the stack has not been initialized.
   * Stack Navigation should be initialized in _app.tsx and should never be fully empty during the running time of the application.
   */
  static get(): RouteHistoryEntry[] {
    if (!windowInitiated) return [];
    const stack =
      sessionStorage.getItem(SessionStorageKeys.StackNavigation) || '[]';
    return JSON.parse(stack);
  }
  /**
   * Replaces the current stack with a new stack. Use this if you need to perform complicated array operations on the stack.
   */
  static set(
    entries: RouteHistoryEntry[],
    options?: {
      saveFuture: boolean;
    },
  ) {
    sessionStorage.setItem(
      SessionStorageKeys.StackNavigation,
      JSON.stringify(uniqueByKeepLast(entries, (item) => item.pathname)),
    );
    if (!options?.saveFuture) {
      this._buffer = [];
    }
  }
  /**
   * Replaces the current entry with another entry. The future buffer is only cleared if the pathnames are divergent, otherwise we assume that the timeline remains the same.
   * Timeline 1: A -> B -> C -> [D -> E]
   * Timeline 2: A -> B -> C' -> [D -> E]
   * is the same, because C = C' even if their scrollY or query may be different, so [D -> E] does not need to be cleared.
   *
   * But:
   * Timeline 1: A -> B -> C -> [D -> E]
   * Timeline 2: A -> B -> F
   * is obviously different, so the future buffer has to be cleared.
   */
  static replace(entry: RouteHistoryEntry) {
    const stack = this.get();
    const previous = stack.pop();
    stack.push(entry);
    this.set(stack, {
      saveFuture: previous?.pathname === entry.pathname,
    });
  }
  /**
   * As its name suggests, pushes a new entry to the stack. There will be no duplicate entries on the stack as only the last entry with the same pathname is kept.
   */
  static push(entry: RouteHistoryEntry): RouteHistoryEntry[] {
    const stack = this.get();
    stack.push(entry);
    this.set(stack);
    return stack;
  }
  /**
   * Pops the latest entry from the stack. This is equivalent to pressing the back button.
   */
  static pop(): RouteHistoryEntry | undefined {
    const stack = this.get();
    const pop = stack.pop();
    this.set(stack, {
      saveFuture: true,
    });
    if (pop) {
      this._buffer.push(pop);
    }
    return pop;
  }
  /**
   * Entries are popped until ``predicate`` returns false or there are no entries left in the stack. It is recommended that you provide a ``fallback`` to prevent the stack from being empty.
   */
  static popWhile(
    predicate: (
      entry: RouteHistoryEntry,
      stack: RouteHistoryEntry[],
    ) => boolean,
    fallback?: RouteHistoryEntry,
  ): RouteHistoryEntry[] {
    const stack = this.get();
    const popped: RouteHistoryEntry[] = [];

    while (stack.length > 0 && predicate(stack[stack.length - 1], stack)) {
      popped.push(stack.pop()!);
    }
    if (fallback && stack.length === 0) {
      stack.push(fallback);
    }
    this.set(stack, {
      saveFuture: true,
    });
    this._buffer.push(...popped);

    return popped;
  }
  /** Gets the previous route; this can be undefined if the stack only has 1 item or less */
  static previous(): RouteHistoryEntry | undefined {
    const stack = this.get();
    return stack.length >= 2 ? stack[stack.length - 2] : undefined;
  }
  /** Gets the current route; this can be undefined if the stack is empty */
  static current(): RouteHistoryEntry | undefined {
    const stack = this.get();
    return stack.length === 0 ? undefined : stack[stack.length - 1];
  }
  /** Clears all other entries except the entry with the same pathname as ``pathname`` */
  static resetTo(pathname: string) {
    const relevantEntry = this.get().find(
      (entry) => entry.pathname === pathname,
    );
    this.set(
      [
        {
          pathname,
          query: {},
          scrollY: relevantEntry?.scrollY || 0,
        },
      ],
      {
        saveFuture: true,
      },
    );
  }

  /** Gets the latest popped entry still stored in ``_buffer`` */
  static future(): RouteHistoryEntry | undefined {
    return this._buffer[this._buffer.length - 1];
  }
  /** Equivalent to pressing the forward button */
  static unpop(): RouteHistoryEntry | undefined {
    const stack = this.get();
    const bufferedFuture = this._buffer.pop();
    if (bufferedFuture) {
      stack.push(bufferedFuture);
      this.set(stack, {
        saveFuture: true,
      });
    }

    return bufferedFuture;
  }

  static update(pathname: string, entry: RouteHistoryEntry) {
    const stack = this.get();
    const idx = stack.findIndex((tab) => tab.pathname === pathname);
    if (idx === -1) return;
    stack[idx] = entry;
    this.set(stack);
  }
}

export class TabNavigation {
  static getDefaultValues(): RouteHistoryEntry[] {
    return [
      {
        pathname: NavigationRoutes.Store,
        scrollY: 0,
      },
      {
        pathname: NavigationRoutes.Orders,
        scrollY: 0,
      },
      {
        pathname: NavigationRoutes.MySim,
        scrollY: 0,
      },
      {
        pathname: NavigationRoutes.Referral,
        scrollY: 0,
      },
      {
        pathname: NavigationRoutes.Profile,
        scrollY: 0,
      },
    ];
  }
  static get(): RouteHistoryEntry[] {
    if (!windowInitiated) return [];
    const stack = sessionStorage.getItem(SessionStorageKeys.TabNavigation);
    return stack ? JSON.parse(stack) : this.getDefaultValues();
  }
  static set(entries: RouteHistoryEntry[]) {
    if (!windowInitiated) return;
    sessionStorage.setItem(
      SessionStorageKeys.TabNavigation,
      JSON.stringify(entries),
    );
  }
  static update(pathname: string, entry: RouteHistoryEntry) {
    const tabs = this.get();
    const idx = tabs.findIndex((tab) => tab.pathname === pathname);
    if (idx === -1) return;
    tabs[idx] = entry;
    this.set(tabs);
  }
}
