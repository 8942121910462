import React from 'react';

interface NotFoundIndicatorProps {
  isNotFound: boolean;
  setNotFound: React.Dispatch<React.SetStateAction<boolean>>;
}

export const NotFoundIndicatorContext =
  React.createContext<NotFoundIndicatorProps>({
    isNotFound: false,
    setNotFound: () => {},
  });

export function NotFoundIndicatorProvider({ children }) {
  const [isNotFound, setNotFound] = React.useState(false);

  return (
    <NotFoundIndicatorContext.Provider value={{ isNotFound, setNotFound }}>
      {children}
    </NotFoundIndicatorContext.Provider>
  );
}

export function useGetNotFoundIndicator() {
  const context = React.useContext(NotFoundIndicatorContext);
  return context;
}
