import { generateUniqueId } from 'common/utils/random';
import { OptionalKeys } from 'common/utils/types';
import React from 'react';

import { BridgeMessageParams, BridgeMessageType } from './bridge-types';

interface BridgeMessage<TKey extends BridgeMessageType = BridgeMessageType> {
  type: TKey;
  identifier: string;
  data: BridgeMessageParams[TKey];
}

interface BridgeContext {
  identifier: string;
  send(message: BridgeMessage): void;
}

interface BridgeProps {
  /** Individual handlers for each message type */
  handlers?: {
    [key in BridgeMessageType]?: (
      message: BridgeMessage<key>,
      context: BridgeContext,
    ) => void;
  };
  /** Global handler for all messages. This runs before the handlers. If this function returns a truthy value, the individual handlers will be used. If not, the handlers will be skipped. */
  global?(message: BridgeMessage, context: BridgeContext): boolean;
  /** Fallback handler if none of the handlers matches the constraint */
  fallback?(message: BridgeMessage, context: BridgeContext): void;
  onSend?(value: string): void;
}
type NativeBridgeProps = BridgeProps & {
  shouldReceive?:
    | boolean
    | ((message: BridgeMessage, context: BridgeContext) => boolean);
};
type WebappBridgeProps = Omit<BridgeProps, 'shouldReceive' | 'onSend'> & {
  onSend(value: string): void;
};

function BridgeMessageHandler(
  props: BridgeProps & {
    context: BridgeContext;
    message: BridgeMessage;
  },
) {
  const { handlers, fallback, global, context, message } = props;
  if (global && !global(message, context)) return;
  if (handlers && handlers[message.type]) {
    handlers[message.type]?.(message as any, context);
  } else if (fallback) {
    fallback(message, context);
  }
}

export const BRIDGE_EVENT_NAME = 'react-native-message';

/* REACT NATIVE */

/** This is used in react native side to send messages */
// eslint-disable-next-line @typescript-eslint/no-unused-vars
function injectRNMessageToWebview(data: BridgeMessage) {
  return `(function (){
  window.dispatchEvent(new CustomEvent("${BRIDGE_EVENT_NAME}", {detail: '${JSON.stringify(data)}'}));
})();`;
}

export function useWebappBridge(props: WebappBridgeProps) {
  const { handlers, global, fallback, onSend } = props;
  const send = (message: BridgeMessage) => {
    onSend(injectRNMessageToWebview(message));
  };

  const receive = (value: string) => {
    const message = JSON.parse(value);
    if (!message.identifier || !message.type) return;
    BridgeMessageHandler({
      context: {
        identifier: '',
        send,
      },
      message,
      fallback,
      global,
      handlers,
    });
  };

  return {
    send,
    receive,
  };
}

/* WEBAPP */

export function sendNativeMessage(
  message: OptionalKeys<BridgeMessage, 'identifier'>,
) {
  const payload = JSON.stringify({
    ...message,
    identifier: message.identifier ?? Math.random().toString(16).substring(2),
  });
  (window as any).ReactNativeWebView?.postMessage(payload);
}

export default function useNativeBridge(props: NativeBridgeProps) {
  const { handlers, global, fallback, shouldReceive, onSend } = props;
  const identifier = React.useRef(generateUniqueId());

  const send = React.useCallback(
    (message: Omit<BridgeMessage, 'identifier'>) => {
      const payload = JSON.stringify({
        ...message,
        identifier: identifier.current,
      });
      if (onSend) {
        onSend(payload);
      } else if (
        typeof window !== 'undefined' &&
        'ReactNativeWebView' in window
      ) {
        (window as any).ReactNativeWebView?.postMessage(payload);
      }
    },
    [onSend],
  );

  const shouldReceiveMessage = (message: CustomEvent<string>) => {
    if (!message.detail || message.type !== BRIDGE_EVENT_NAME) return null;
    const payload = JSON.parse(message.detail);

    const invalidMessage = !payload.identifier || !payload.type;
    const messageDoesntHaveTheSameIdentifier =
      shouldReceive === false && payload.identifier !== identifier.current;
    const messageShouldntBeReceived =
      typeof shouldReceive === 'function' &&
      !shouldReceive(payload, {
        identifier: identifier.current,
        send,
      });
    if (
      invalidMessage ||
      messageDoesntHaveTheSameIdentifier ||
      messageShouldntBeReceived
    ) {
      return null;
    }
    return payload;
  };

  React.useEffect(() => {
    function onReactNativeMessage(event: CustomEvent<string>) {
      const message = shouldReceiveMessage(event);
      if (!message) return;
      BridgeMessageHandler({
        handlers,
        global,
        fallback,
        context: {
          identifier: identifier.current,
          send,
        },
        message,
      });
    }
    window.addEventListener(BRIDGE_EVENT_NAME, onReactNativeMessage);
    return () =>
      window.removeEventListener(BRIDGE_EVENT_NAME, onReactNativeMessage);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (typeof window !== 'undefined' && 'ReactNativeWebView' in window) ||
    onSend
    ? send
    : undefined;
}
