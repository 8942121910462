import { EXTERNAL_LINK } from 'common/constants/external-link';
import React from 'react';

import { useExternalSiteNavigation } from './use-kurosim-navigation/navigator';

const defaultMessage = 'Hi+Kurosim';

export function useWhatsappRedirection(message = defaultMessage) {
  const redirect = useExternalSiteNavigation();

  const handleWhatsappRedirection = React.useCallback(() => {
    redirect(EXTERNAL_LINK.WHATSAPP_WEB_KUROSIM(message));
  }, [message, redirect]);

  return handleWhatsappRedirection;
}
