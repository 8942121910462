import { useQuery } from '@tanstack/react-query';
import { getParamsInput } from 'api-hooks/common';
import { QueryFetchFunction, QueryTransformer } from 'common/api/fetch';
import { ApiQueryFunction } from 'common/api/fetch-types';
import { ApiResult } from 'common/api/model';

import { LinkedDeviceModel } from './model';

export const accountKey = {
  linkedDevicesKey: 'getLinkedDevices',
  linkedDevices(input: getParamsInput) {
    return [accountKey.linkedDevicesKey, input.params];
  },
};

const ENDPOINT = 'me/sessions';

export const useGetLinkedDevices: ApiQueryFunction<
  getParamsInput,
  ApiResult<LinkedDeviceModel[]>
> = (input, options) => {
  return QueryTransformer(
    useQuery(
      accountKey.linkedDevices(input),
      () => {
        return QueryFetchFunction({
          url: ENDPOINT,
        });
      },
      options,
    ),
    LinkedDeviceModel,
  );
};
