import React from 'react';

export function useEffectLimited(
  fn: Parameters<typeof React.useEffect>[0],
  dependencies: Parameters<typeof React.useEffect>[1],
  options: {
    /** Should useEffect be executed when the dependencies have changed */
    condition?: (times: number) => boolean;
    /** How many times is this useEffect allowed to execute */
    times?: number;
  },
) {
  const count = React.useRef(0);
  React.useEffect(() => {
    const fulfillsCondition =
      !options?.condition || options.condition(count.current);
    const fulfillsTimes =
      options?.times == null || options.times > count.current;
    if (fulfillsCondition && fulfillsTimes) {
      const returned = fn();
      count.current++;
      return returned;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, dependencies);
}

interface UseStateIfMountedProps<T> {
  /** Initial value of the useState hook. This is optional */
  initialValue?: T | undefined;
  /** The function to get the actual initial value value */
  initializer(): Promise<T> | T;
}
export function useStateIfMounted<T>(
  props: UseStateIfMountedProps<T>,
): [T | undefined, React.Dispatch<React.SetStateAction<T>>] {
  const [state, setState] = React.useState(props.initialValue);
  useEffectLimited(
    () => {
      (async function () {
        setState(await props.initializer());
      })();
    },
    [],
    {
      times: 1,
    },
  );

  return [state, setState];
}

interface UseAsyncFunctionLoadingStateReturn<
  T extends (...args: any) => Promise<any>,
> {
  loading: boolean;
  error: any;
  fn: T;
}
export function useAsyncFunctionLoadingState<
  T extends (...args: any) => Promise<any>,
>(fn: T): UseAsyncFunctionLoadingStateReturn<T> {
  const [loading, setLoading] = React.useState<boolean>(false);
  const [error, setError] = React.useState<any>(null);
  return {
    loading,
    error,
    fn: (async (...args) => {
      setLoading(true);
      try {
        const result = await fn(...args);
        setLoading(false);
        return result;
      } catch (e) {
        setError(e);
        setLoading(false);
      }
    }) as T,
  };
}
