import { useRouter } from 'next/router';
import React from 'react';

import useKurosimNavigation from './navigator';
import type { KurosimNavigator } from './types';

interface UseInterPageDataProps {
  /** Which url to return to when there's no data */
  abort(navigator: KurosimNavigator): void;
}

export const LOCKER_IDENTIFIER = 'lck';
/**
 * Data that shouldn't appear in the url (query) should be passed between pages using this context.
 * Each data is stored using a lockerId which should be included in the URL as ``LOCKER_IDENTIFIER``.
 */
export const InterPageDataContext = React.createContext(Object.create(null));

/**
 * Accesses data from ``InterPageDataContext`` and cleans up the unused locker after the page is unmounted.
 * Set ``shouldCleanup`` to false if you're using this hook in a nested component
 */
export function useInterPageData<T>(
  props?: UseInterPageDataProps,
): T | undefined {
  const lockers = React.useContext(InterPageDataContext);
  // eslint-disable-next-line @typescript-eslint/no-use-before-define
  const navigation = useKurosimNavigation();
  // Get the locker id from url
  const lockerId = useRouter().query?.[LOCKER_IDENTIFIER] as string;

  // Use useRef to only get the data once. The value of useRef will not be modified again in the future, which means that as long as the locker data had existed at the time of opening this page, the abort procedure will never be called.
  // This is because useKurosimNavigation.back() clears any existing locker context, which causes useInterPageData abort function to be triggered before back() can do its thing.
  const lockerData = React.useRef(lockerId ? lockers[lockerId] : undefined);

  if (!lockerData.current && props?.abort) {
    props.abort(navigation);
    return undefined;
  }

  // Access the locker with that lockerId
  return lockerData.current;
}

export function InterPageDataProvider(props: React.PropsWithChildren) {
  const lockers = React.useRef(Object.create(null));
  return (
    <InterPageDataContext.Provider value={lockers.current}>
      {props.children}
    </InterPageDataContext.Provider>
  );
}
