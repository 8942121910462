import {
  useMutation,
  UseMutationOptions,
  UseMutationResult,
} from '@tanstack/react-query';
import { MutationFetchFunction } from 'common/api/fetch';
import { ApiError, ApiResult, MessageResult } from 'common/api/model';
import { queryClient } from 'common/api/query-client';
import { SessionToken } from 'common/auth';
import { mutateRefreshToken, TokenResultModel } from 'common/auth/api';

import {
  ChangeEmailMutationInput,
  ChangePasswordMutationInput,
  CheckEmailModel,
  CheckEmailMutationInput,
  DeleteAccountMutationInput,
  LoginMutationInput,
  PatchSubscribeInput,
  RefreshTokenMutationInput,
  RegisterMutationInput,
  ResetPasswordMutationInput,
  RevokeMutationInput,
  SendOtpMutationInput,
  SendOtpResultModel,
  UserModel,
  VerifyEmailMutationInput,
  VerifyOtpModel,
  VerifyOtpMutationInput,
  ViaProviderMutationInput,
} from './model';
import { meKey } from './query';

// remember to update endpoint in ``mutateRefreshToken`` if this ever changes.
const ENDPOINT = 'auth';
const ME_ENDPOINT = `me/${ENDPOINT}`;

export function useLogin(
  options?: UseMutationOptions<
    ApiResult<TokenResultModel>,
    ApiError,
    LoginMutationInput
  >,
): UseMutationResult<
  ApiResult<TokenResultModel>,
  ApiError,
  LoginMutationInput
> {
  return useMutation<ApiResult<TokenResultModel>, ApiError, LoginMutationInput>(
    async function (body) {
      return await MutationFetchFunction({
        url: `${ENDPOINT}/login`,
        method: 'POST',
        classType: TokenResultModel,
        body,
      });
    },
    {
      onSuccess(data: ApiResult<TokenResultModel>) {
        SessionToken.set(data.data);
        queryClient.invalidateQueries();
        queryClient.invalidateQueries(meKey.getMeKey);
      },
      ...options,
    },
  );
}

export function useViaProvider(
  options?: UseMutationOptions<
    ApiResult<TokenResultModel>,
    ApiError,
    ViaProviderMutationInput
  >,
): UseMutationResult<
  ApiResult<TokenResultModel>,
  ApiError,
  ViaProviderMutationInput
> {
  return useMutation<
    ApiResult<TokenResultModel>,
    ApiError,
    ViaProviderMutationInput
  >(async function (body) {
    return await MutationFetchFunction({
      url: `${ENDPOINT}/via-provider`,
      method: 'POST',
      body,
      classType: TokenResultModel,
    });
  }, options);
}

export function useRefreshToken(
  options?: UseMutationOptions<
    ApiResult<TokenResultModel>,
    ApiError,
    RefreshTokenMutationInput
  >,
): UseMutationResult<
  ApiResult<TokenResultModel>,
  ApiError,
  RefreshTokenMutationInput
> {
  return useMutation<
    ApiResult<TokenResultModel>,
    ApiError,
    RefreshTokenMutationInput
  >(async (body) => await mutateRefreshToken(body)!, options);
}

export function useCheckEmail(
  options?: UseMutationOptions<
    ApiResult<CheckEmailModel>,
    ApiError,
    CheckEmailMutationInput
  >,
): UseMutationResult<
  ApiResult<CheckEmailModel>,
  ApiError,
  CheckEmailMutationInput
> {
  return useMutation<
    ApiResult<CheckEmailModel>,
    ApiError,
    CheckEmailMutationInput
  >(async function (body) {
    return MutationFetchFunction({
      url: `${ENDPOINT}/check-email`,
      method: 'POST',
      body,
      classType: CheckEmailModel,
    });
  }, options);
}

export function useSendOtp(
  options?: UseMutationOptions<
    ApiResult<SendOtpResultModel | null>,
    ApiError,
    SendOtpMutationInput
  >,
): UseMutationResult<
  ApiResult<SendOtpResultModel | null>,
  ApiError,
  SendOtpMutationInput
> {
  return useMutation<
    ApiResult<SendOtpResultModel | null>,
    ApiError,
    SendOtpMutationInput
  >(async function (body) {
    return MutationFetchFunction({
      method: 'POST',
      url: `${ENDPOINT}/send-otp`,
      body,
      // TODO: Assign Return Model
      classType: SendOtpResultModel,
    });
  }, options);
}

export function useVerifyOtp(
  options?: UseMutationOptions<
    ApiResult<VerifyOtpModel>,
    ApiError,
    VerifyOtpMutationInput
  >,
): UseMutationResult<
  ApiResult<VerifyOtpModel>,
  ApiError,
  VerifyOtpMutationInput
> {
  return useMutation<
    ApiResult<VerifyOtpModel>,
    ApiError,
    VerifyOtpMutationInput
  >(async function (body) {
    return MutationFetchFunction({
      method: 'POST',
      url: `${ENDPOINT}/verify-otp`,
      body,

      classType: VerifyOtpModel,
    });
  }, options);
}

export function useRegister(
  options?: UseMutationOptions<
    ApiResult<TokenResultModel>,
    ApiError,
    RegisterMutationInput
  >,
): UseMutationResult<
  ApiResult<TokenResultModel>,
  ApiError,
  RegisterMutationInput
> {
  return useMutation<
    ApiResult<TokenResultModel>,
    ApiError,
    RegisterMutationInput
  >(async function (body) {
    return MutationFetchFunction({
      method: 'POST',
      url: `${ENDPOINT}/register`,
      body,
      classType: TokenResultModel,
    });
  }, options);
}

export function useRevoke(
  options?: UseMutationOptions<
    { message: string },
    ApiError,
    RevokeMutationInput
  >,
): UseMutationResult<{ message: string }, ApiError, RevokeMutationInput> {
  return useMutation<{ message: string }, ApiError, RevokeMutationInput>(
    async function (body) {
      return MutationFetchFunction({
        method: 'POST',
        url: `${ENDPOINT}/revoke`,
        header: {
          Authorization: `Bearer ${body.accessToken}`,
        },
      });
    },
    options,
  );
}

export function useChangeEmail(
  options?: UseMutationOptions<
    ApiResult<UserModel>,
    ApiError,
    ChangeEmailMutationInput
  >,
): UseMutationResult<ApiResult<UserModel>, ApiError, ChangeEmailMutationInput> {
  return useMutation<ApiResult<UserModel>, ApiError, ChangeEmailMutationInput>(
    async function (body) {
      return MutationFetchFunction({
        method: 'POST',
        url: `${ME_ENDPOINT}/change-email`,
        body,
        classType: UserModel,
      });
    },
    {
      onSuccess() {
        queryClient.invalidateQueries(meKey.getMeKey);
      },
      ...options,
    },
  );
}

export function useChangePassword(
  options?: UseMutationOptions<
    ApiResult<any>,
    ApiError,
    ChangePasswordMutationInput
  >,
): UseMutationResult<ApiResult<any>, ApiError, ChangePasswordMutationInput> {
  return useMutation<ApiResult<any>, ApiError, ChangePasswordMutationInput>(
    async function (body) {
      return MutationFetchFunction({
        method: 'POST',
        url: `${ME_ENDPOINT}/change-password`,
        body,
        // TODO: Assign Return Model
        // classType: any,
      });
    },
    options,
  );
}

export function useDeleteAccount(
  options?: UseMutationOptions<
    { message: string },
    ApiError,
    DeleteAccountMutationInput
  >,
): UseMutationResult<
  { message: string },
  ApiError,
  DeleteAccountMutationInput
> {
  return useMutation<{ message: string }, ApiError, DeleteAccountMutationInput>(
    async function (body) {
      return MutationFetchFunction({
        method: 'PATCH',
        url: `${ME_ENDPOINT}/delete-account`,
        body,
      });
    },
    options,
  );
}

export function useVerifyEmail(
  options?: UseMutationOptions<
    { message: string },
    ApiError,
    VerifyEmailMutationInput
  >,
): UseMutationResult<{ message: string }, ApiError, VerifyEmailMutationInput> {
  return useMutation<{ message: string }, ApiError, VerifyEmailMutationInput>(
    async function (body) {
      return MutationFetchFunction({
        method: 'POST',
        url: `${ME_ENDPOINT}/verify-email`,
        body,
      });
    },
    {
      onSuccess() {
        queryClient.invalidateQueries(meKey.getMeKey);
      },
      ...options,
    },
  );
}

export function useResetPassword(
  options?: UseMutationOptions<
    { message: string },
    ApiError,
    ResetPasswordMutationInput
  >,
): UseMutationResult<
  { message: string },
  ApiError,
  ResetPasswordMutationInput
> {
  return useMutation<{ message: string }, ApiError, ResetPasswordMutationInput>(
    async function (body) {
      return MutationFetchFunction({
        method: 'POST',
        url: `${ENDPOINT}/reset-password`,
        body,
      });
    },
    options,
  );
}

export function usePatchSubscribe(
  options?: UseMutationOptions<MessageResult, ApiError, PatchSubscribeInput>,
): UseMutationResult<MessageResult, ApiError, PatchSubscribeInput> {
  return useMutation<MessageResult, ApiError, PatchSubscribeInput>(
    async function (body) {
      return MutationFetchFunction({
        method: 'PATCH',
        url: `${ME_ENDPOINT}/subscribe`,
        body,
      });
    },
    options,
  );
}
