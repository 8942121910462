import React from 'react';

import Text, { TextProps } from './base';

interface MultilineTextProps extends TextProps {
  separator?: string;
  gap?: number;
  keyExtractor?(value: string, index: number): string;
}

export default function MultilineText(props: MultilineTextProps) {
  const { separator, gap, children, keyExtractor, ...textProps } = props;
  return typeof children === 'string' ? (
    children.split('\n').map((x, i, arr) => (
      <Text
        mb={i === arr.length - 1 ? undefined : gap}
        {...textProps}
        key={keyExtractor ? keyExtractor(x, i) : i}
      >
        {x}
      </Text>
    ))
  ) : (
    <Text {...textProps}>{children}</Text>
  );
}
