import React from 'react';

export default function InfinityIcon(props: React.SVGProps<SVGSVGElement>) {
  const { color, ...restProps } = props;
  return (
    <svg
      width="19"
      height="10"
      viewBox="0 0 19 10"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...restProps}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M4.98473 1.93506C4.37854 1.81447 3.75019 1.87634 3.17916 2.11286C2.60813 2.34938 2.12006 2.74992 1.77667 3.26382C1.43328 3.77773 1.25 4.38193 1.25 5C1.25 5.61808 1.43328 6.22227 1.77667 6.73618C2.12006 7.25009 2.60813 7.65063 3.17916 7.88715C3.75019 8.12366 4.37854 8.18554 4.98473 8.06494C5.58504 7.94552 6.13692 7.65247 6.572 7.22235L7.2378 6.47073C7.46669 6.21235 7.86169 6.18844 8.12007 6.41732C8.37845 6.6462 8.40237 7.0412 8.17348 7.29959L7.49458 8.06599C7.48623 8.07542 7.4776 8.08459 7.4687 8.09349C6.85685 8.70538 6.0773 9.12208 5.22863 9.29092C4.37995 9.45975 3.50027 9.37313 2.70083 9.042C1.90138 8.71088 1.21808 8.15012 0.737341 7.43065C0.256597 6.71118 0 5.86531 0 5C0 4.1347 0.256597 3.28882 0.737341 2.56935C1.21808 1.84988 1.90138 1.28913 2.70083 0.958001C3.50027 0.626877 4.37995 0.540254 5.22863 0.709088C6.0773 0.877921 6.85685 1.29463 7.4687 1.90651C7.47761 1.91543 7.48625 1.92461 7.49461 1.93405L12.1784 7.22236C12.6134 7.65248 13.1653 7.94552 13.7656 8.06494C14.3718 8.18554 15.0002 8.12366 15.5712 7.88715C16.1422 7.65063 16.6303 7.25009 16.9737 6.73618C17.3171 6.22227 17.5004 5.61808 17.5004 5C17.5004 4.38193 17.3171 3.77773 16.9737 3.26382C16.6303 2.74992 16.1422 2.34938 15.5712 2.11286C15.0002 1.87634 14.3718 1.81447 13.7656 1.93506C13.1653 2.05449 12.6134 2.34753 12.1784 2.77766L11.5125 3.52927C11.2837 3.78765 10.8887 3.81157 10.6303 3.58269C10.3719 3.3538 10.348 2.9588 10.5769 2.70042L11.2558 1.93401C11.2641 1.92459 11.2728 1.91542 11.2817 1.90651C11.8935 1.29463 12.6731 0.877921 13.5217 0.709088C14.3704 0.540254 15.2501 0.626877 16.0495 0.958001C16.849 1.28913 17.5323 1.84988 18.013 2.56935C18.4938 3.28882 18.7504 4.1347 18.7504 5C18.7504 5.86531 18.4938 6.71118 18.013 7.43065C17.5323 8.15012 16.849 8.71088 16.0495 9.042C15.2501 9.37313 14.3704 9.45975 13.5217 9.29092C12.6731 9.12208 11.8935 8.70538 11.2817 8.09349C11.2727 8.08458 11.2641 8.07539 11.2557 8.06595L6.57198 2.77764C6.1369 2.34753 5.58503 2.05448 4.98473 1.93506Z"
        fill={color ?? 'black'}
      />
    </svg>
  );
}
