import { useSessionStorage } from '@mantine/hooks';
import { useAuth } from 'common/auth';
import exportEnv from 'common/config';
import { SessionStorageKeys } from 'common/constants/browser-storage-keys';
import useNativeBridge from 'common/routes/bridge';
import { BridgeMessageType } from 'common/routes/bridge-types';
import { generateUniqueId } from 'common/utils/random';
import Separator from 'components/common/separator';
import Button from 'components/elements/button';
import BottomSheet, {
  BottomSheetRemote,
} from 'components/widgets/bottom-sheet';
import { Crisp } from 'crisp-sdk-web';
import useCombinedRefs from 'hooks/use-combined-refs';
import useDetectDevice from 'hooks/use-detect-device';
import {
  PopStateListenerChain,
  useRegisterKurosimPopStateListener,
} from 'hooks/use-kurosim-navigation/popstate';
import { useWhatsappRedirection } from 'hooks/use-whatsapp-redirection';
import useTranslation from 'next-translate/useTranslation';
import React from 'react';

interface CrispContextType {
  unreadCount: number;
  show(): void;
  hide(): void;
  isVisible(): boolean;
  ready: boolean;
}
export const CrispContext = React.createContext<CrispContextType>({
  unreadCount: 0,
  show() {},
  hide() {},
  isVisible() {
    return false;
  },
  ready: false,
});

let CRISP_IS_READY = false;

export function CrispProvider(props: React.PropsWithChildren) {
  const { t } = useTranslation();
  const query = useAuth();
  const [unreadCount, setUnreadCount] = React.useState(0);
  const [sessionLoaded, setSessionLoaded] = React.useState(false);
  const { isKurosimApp } = useDetectDevice();

  const previousTitle = React.useRef('Kurosim');

  const send = useNativeBridge({});

  React.useEffect(() => {
    Crisp.configure(exportEnv.crispWebsiteId, {
      autoload: false,
      lockFullview: true,
      sessionMerge: false,
    });
    Crisp.setHideOnMobile(false);
    Crisp.setVacationMode(true);

    Crisp.chat.onChatClosed(() => {
      Crisp.chat.hide();
      document.title = previousTitle.current;
      // Reset register
      previousTitle.current = 'Kurosim';
    });
    Crisp.message.onMessageReceived(async (data) => {
      setUnreadCount(Crisp.chat.unreadCount());
    });

    const onReady = () => {
      setUnreadCount(Crisp.chat.unreadCount());
      setSessionLoaded(true);
      Crisp.chat.hide();
      console.log('[Crisp] Session successfully loaded.');
    };
    Crisp.session.onLoaded(onReady);

    // https://docs.crisp.chat/guides/chatbox-sdks/web-sdk/dollar-crisp/#use-crisp-before-it-is-ready
    (window as any).CRISP_READY_TRIGGER = () => {
      console.log('[Crisp] Crisp functionality has been loaded.');
      CRISP_IS_READY = true;
    };
    return () => {
      if ('$crisp' in window) {
        window.$crisp.push(['off', 'message:received']);
        window.$crisp.push(['off', 'session:loaded']);
      }
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const me = query?.data?.data;
  // This value should persist while browser is open
  const [anonymousId] = useSessionStorage({
    defaultValue: generateUniqueId(),
    key: SessionStorageKeys.CrispAnonymousSessionId,
  });
  const crispTokenId = me?.id ?? anonymousId;
  const lastUsedTokenId = React.useRef<string | null>(null);
  const nickname = me
    ? [me.firstName, me.lastName].filter(Boolean).join(' ')
    : `Guest ${anonymousId}`;

  React.useEffect(() => {
    if (query.isFetching || lastUsedTokenId.current === crispTokenId) return;

    if (me) {
      // https://docs.crisp.chat/guides/chatbox-sdks/web-sdk/session-continuity/
      Crisp.user.setEmail(me.email);
    }

    Crisp.user.setNickname(nickname);
    Crisp.setTokenId(crispTokenId);
    lastUsedTokenId.current = crispTokenId;
    if (!CRISP_IS_READY) {
      Crisp.load();
      console.log('[Crisp] Initializing Crisp...');
    } else {
      try {
        // Crisp.session.reset();
        window.$crisp.push(['do', 'session:reset']);
        console.log(
          '[Crisp] Detected change in login state. Resetting current session...',
        );
        setSessionLoaded(false);
      } catch (e) {
        console.error(e);
      }
    }
    setUnreadCount(0);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [crispTokenId, query.isFetching]);

  const show = React.useCallback(() => {
    if (isKurosimApp) {
      send?.({
        data: {
          email: me?.email,
          nickname,
          crispTokenId,
        },
        type: BridgeMessageType.LiveChat,
      });
    } else {
      // Very, very dubious hack especially since we're using next/head to set the title. Remove this if it causes weird behavior.
      Crisp.chat.show();
      previousTitle.current = document.title;
      document.title = `${t('title:live-chat')} | Kurosim`;
      Crisp.chat.open();
      window.history.pushState(
        {
          ...window.history.state,
          key: Math.random().toString(16).substring(2),
          crisp: true,
        },
        '',
        window.location.pathname,
      );
    }
  }, [crispTokenId, isKurosimApp, me?.email, nickname, send, t]);

  const hide = React.useCallback(() => {
    Crisp.chat.hide();
    document.title = previousTitle.current;
  }, []);

  const isVisible = React.useCallback(() => Crisp.chat.isVisible(), []);

  return (
    <CrispContext.Provider
      value={{
        show,
        hide,
        isVisible,
        ready: sessionLoaded,
        unreadCount,
      }}
    >
      {props?.children}
    </CrispContext.Provider>
  );
}

export const LiveChatBottomSheet = React.forwardRef<BottomSheetRemote, object>(
  function LiveChatBottomSheet(props, outerRef) {
    const { t } = useTranslation();
    const innerRef = React.useRef<BottomSheetRemote | null>();
    const ref = useCombinedRefs(innerRef, outerRef);
    const whatsapp = useWhatsappRedirection();

    const {
      hide: hideLiveChat,
      show: showLiveChat,
      ready,
    } = React.useContext(CrispContext);

    useRegisterKurosimPopStateListener(
      'live-chat',
      (event) => {
        if (event.state?.crisp) {
          showLiveChat();
          return PopStateListenerChain.NoDefault;
        }
        if (Crisp.chat.isVisible()) {
          hideLiveChat();
          return PopStateListenerChain.Continue;
        }
        return PopStateListenerChain.Continue;
      },
      10,
    );

    return (
      <BottomSheet
        defaultSnap={240}
        ref={ref as any}
        title={t('profile:contact_us')}
      >
        <div>
          <Button
            onClick={() => {
              showLiveChat();
              innerRef.current?.close();
            }}
            fullWidth
            loading={!ready}
          >
            {t('profile:live_chat')}
          </Button>
          <Separator gap={16} />
          <Button
            variant={{ variant: 'secondary' }}
            fullWidth
            onClick={whatsapp}
          >
            {t('profile:whatsapp')}
          </Button>
        </div>
      </BottomSheet>
    );
  },
);
