export default function FadedKurosimLogo(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="179"
      height="215"
      viewBox="0 0 179 215"
      fill="none"
      {...props}
    >
      <g
        style={{
          mixBlendMode: 'overlay',
        }}
        clipPath="url(#clip0_3492_5558)"
      >
        <path
          d="M149.579 119.238L115.804 167.448L144.016 215H209.534L149.579 119.238Z"
          fill="url(#paint0_linear_3492_5558)"
        />
        <path
          d="M207.989 -3L55.3195 214.997H0V-3H55.3195V116.375L139.38 -3H207.989Z"
          fill="url(#paint1_linear_3492_5558)"
        />
      </g>
      <defs>
        <linearGradient
          id="paint0_linear_3492_5558"
          x1="162.669"
          y1="119.238"
          x2="162.669"
          y2="215"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0.225" stopColor="white" />
          <stop offset="1" stopColor="#1C1C1C" stopOpacity="0" />
        </linearGradient>
        <linearGradient
          id="paint1_linear_3492_5558"
          x1="103.994"
          y1="-3"
          x2="103.994"
          y2="214.997"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0.225" stopColor="white" />
          <stop offset="1" stopColor="#1C1C1C" stopOpacity="0" />
        </linearGradient>
        <clipPath id="clip0_3492_5558">
          <rect
            width="209.534"
            height="218"
            fill="white"
            transform="translate(0 -3)"
          />
        </clipPath>
      </defs>
    </svg>
  );
}
