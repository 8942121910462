export {
  X as CancelIcon,
  XCircle as XCircleIcon,
  CheckCircle as CheckCircleIcon,
  ImageSquare as ImageSquareIcon,
  ArrowLeft as ArrowLeftIcon,
  Gauge as GaugeIcon,
  CreditCard as CreditCardIcon,
  Alarm as AlarmIcon,
  Check as CheckIcon,
  WarningCircle as WarningCircleIcon,
  HouseSimple as HomeIcon,
  GlobeHemisphereEast as BrowseIcon,
  SimCard as SimIcon,
  User as ProfileIcon,
  ShoppingBag as ShoppingBagIcon,
  ShoppingCartSimple as ShoppingCartIcon,
  Bell as NotificationIcon,
  ArrowRight as ArrowRightIcon,
  Info as InfoIcon,
  Copy as CopyIcon,
  TrashSimple as TrashSimpleIcon,
  Medal as MedalIcon,
  Cpu as CpuIcon,
  Broadcast as BroadcastIcon,
  MagnifyingGlass as SearchIcon,
  ArchiveBox as ArchiveIcon,
  DotsThree as MoreIcon,
  ShareNetwork as ShareIcon,
  UploadSimple as UploadIcon,
  Globe as GlobalIcon,
  DownloadSimple as DownloadSimpleIcon,
  Notebook as NotebookIcon,
  CellSignalFull as CellNetworkIcon,
  File as FileIcon,
  Question as HelpIcon,
  Gift as GiftIcon,
  Tag as TagIcon,
  Notebook as OrdersIcon,
  Storefront as StoreIcon,
  ArrowsDownUp as ArrowsDownUpIcon,
  CalendarBlank as CalendarBlankIcon,
  Coins as CoinsIcon,
  Minus as MinusIcon,
  ChatCircleText as ChatIcon,
  PencilSimple as EditIcon,
  Plus as CreateIcon,
  CaretDown as CaretDownIcon,
  ChatTeardropDots as ChatTeardropDotsIcon,
  PlusCircle as PlusCircleIcon,
  DotsThreeCircle as DotsThreeCircleIcon,
  NotePencil as NotePencilIcon,
  Palette as PaletteIcon,
  PaperPlaneTilt as PaperPlaneTiltIcon,
  Medal as KuroPointsIcon,
  IdentificationCard as AccountSettingsIcon,
  ClockCounterClockwise as SubscriptionIcon,
  SignOut as LogoutIcon,
  Devices as DevicesIcon,
  CrownSimple as CrownSimpleIcon,
  Envelope as EnvelopeIcon,
} from '@phosphor-icons/react';
export { default as AddShoppingCardSimpleIcon } from './svg/add-shopping-card-simple';
export { default as InfinityIcon } from './svg/infinity';
export { default as CellRangeIcon } from './svg/cell-range';
export { default as FadedKurosimLogo } from './svg/faded-kurosim-logo';
export { default as AddToMobileIcon } from './svg/add-to-mobile';
export { PremiumPlanIcon } from './loader/phosphor-wrapper';
