import invariant from 'invariant';

function required(name: string, value?: string): string {
  invariant(value !== undefined, `${name} is required on env vars`);
  return value!;
}

const exportEnv = {
  apiEndpoint: required(
    'NEXT_PUBLIC_API_ENDPOINT',
    process.env.NEXT_PUBLIC_API_ENDPOINT,
  ),
  projectEnv: required(
    'NEXT_PUBLIC_PROJECT_ENV',
    process.env.NEXT_PUBLIC_PROJECT_ENV,
  ),
  crispWebsiteId: required(
    'NEXT_PUBLIC_CRISP_WEBSITE_ID',
    process.env.NEXT_PUBLIC_CRISP_WEBSITE_ID,
  ),
  appleId: process.env.APPLE_ID!,
  appleSecret: process.env.APPLE_SECRET!,
  appleRedirectUri: process.env.APPLE_REDIRECT_URI!,
  googleId: process.env.GOOGLE_ID!,
  googleSecret: process.env.GOOGLE_SECRET!,
  onesignalAppId: required(
    'NEXT_PUBLIC_ONESIGNAL_APP_ID',
    process.env.NEXT_PUBLIC_ONESIGNAL_APP_ID,
  ),
  onesignalRestApiKey: required(
    'NEXT_PUBLIC_ONESIGNAL_REST_API_KEY',
    process.env.NEXT_PUBLIC_ONESIGNAL_REST_API_KEY,
  ),
  xenditApiKey: required(
    'NEXT_PUBLIC_XENDIT_API_KEY',
    process.env.NEXT_PUBLIC_XENDIT_API_KEY,
  ),
  cloudflareSiteKey: required(
    'NEXT_PUBLIC_CLOUDFLARE_SITE_KEY',
    process.env.NEXT_PUBLIC_CLOUDFLARE_SITE_KEY,
  ),
  kurosimPhoneNumber: '+6285186821131',
};

export default exportEnv;
