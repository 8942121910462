import { CloseButton, CloseButtonProps } from '@mantine/core';
import { useUncontrolled } from '@mantine/hooks';
import { CancelIcon } from 'common/assets';
import {
  CustomizationUnit,
  applyCustomization,
} from 'common/repositories/customization';
import Text, { TextProps } from 'components/elements/text';
import { MOBILE_CONTAINER_MAX_WIDTH } from 'modules/components/styles.css';
import React, { useImperativeHandle } from 'react';
import {
  BottomSheet as RawBottomSheet,
  type BottomSheetRef,
  type BottomSheetProps,
} from 'react-spring-bottom-sheet';
import structuralStyles from 'styles/layout.css';

import { BottomSheetStyles } from './styles.css';

export interface BottomSheetRemote {
  open(): void;
  close(): void;
  height(): number;
  snapTo: BottomSheetRef['snapTo'];
}

interface BottomSheetCustomization {
  header?: {
    title?: TextProps;
    closeButton?: CloseButtonProps & {
      render?: React.ReactNode;
    };
    extendedComponent?: React.ReactNode;
  };
  body?: CustomizationUnit<React.ComponentProps<'div'>>;
}

interface Props extends Omit<BottomSheetProps, 'open' | 'ref'> {
  title?: string;
  customization?: BottomSheetCustomization;
  open?: boolean;
  setOpen?(value: boolean): void;
}

const BottomSheet = React.forwardRef<BottomSheetRemote, Props>(
  function BottomSheet(props, ref) {
    const {
      open: controlledOpen,
      setOpen: setControlledOpen,
      customization,
      title,
      children,
      ...restProps
    } = props;
    const [open, setOpen] = useUncontrolled({
      value: controlledOpen,
      defaultValue: false,
      onChange: setControlledOpen,
    });
    const innerRef = React.useRef<BottomSheetRef | null>(null);
    useImperativeHandle<any, BottomSheetRemote>(ref, () => ({
      height: () => innerRef.current?.height ?? 0,
      snapTo: ((...args) =>
        innerRef.current?.snapTo(...args)) as BottomSheetRef['snapTo'],
      open: () => setOpen(true),
      close: () => setOpen(false),
    }));
    return (
      <RawBottomSheet
        open={open}
        ref={innerRef}
        onDismiss={() => {
          setOpen(false);
        }}
        onClick={(e) => e.stopPropagation()}
        style={
          {
            '--rsbs-max-w': `${MOBILE_CONTAINER_MAX_WIDTH + 32}px`,
            '--rsbs-ml': `auto`,
            '--rsbs-mr': `auto`,
          } as React.CSSProperties
        }
        header={
          <div>
            <div className={structuralStyles.flexbox({ justify: 'between' })}>
              {customization?.header?.closeButton?.render || (
                <CloseButton
                  disabled
                  icon={<CancelIcon size={18} color="white" />}
                  {...customization?.header?.closeButton}
                />
              )}
              <Text textVariant="h3" {...customization?.header?.title}>
                {title}
              </Text>
              {customization?.header?.closeButton?.render || (
                <CloseButton
                  icon={<CancelIcon size={18} />}
                  onClick={(e) => {
                    setOpen(false);
                    e?.stopPropagation();
                  }}
                  {...customization?.header?.closeButton}
                />
              )}
            </div>
            {customization?.header?.extendedComponent}
          </div>
        }
        // https://github.com/stipsan/react-spring-bottom-sheet/issues/180#issuecomment-1399301154
        sibling={
          <div
            data-rsbs-backdrop="true"
            onClick={(e) => {
              e.stopPropagation();
              e.preventDefault();
              setOpen(false);
            }}
          />
        }
        blocking={false} // https://github.com/stipsan/react-spring-bottom-sheet/issues/180#issuecomment-1399301154
        defaultSnap={({ maxHeight }) => maxHeight * 0.6}
        snapPoints={({ maxHeight }) => [
          maxHeight - maxHeight * 0.1,
          maxHeight * 0.6,
          maxHeight * 0.4,
        ]}
        {...restProps}
      >
        <div
          {...applyCustomization({ className: BottomSheetStyles.bodyPadding }, [
            customization?.body,
          ])}
        >
          {children}
        </div>
      </RawBottomSheet>
    );
  },
);
export default BottomSheet;
