import { useViaProvider, ViaProviderMutationInput } from 'api-hooks/auth';
import { queryClient } from 'common/api/query-client';
import React from 'react';

import { SessionToken } from './token';

export default function useOauthSession() {
  const viaProviderMutation = useViaProvider();
  const { mutateAsync: viaProviderAsync } = viaProviderMutation;
  const onGetToken = React.useCallback(
    async (value: ViaProviderMutationInput) => {
      const result = await viaProviderAsync(value);

      SessionToken.set(result.data);
      queryClient.invalidateQueries();

      return result;
    },
    [viaProviderAsync],
  );

  return { onGetToken, viaProviderMutation };
}
