interface Props extends React.SVGProps<SVGSVGElement> {
  size: number;
}

export default function AddToMobileIcon(props: Props) {
  return (
    <svg
      width={props.size}
      height={props.size}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M16.5 3C16.9142 3 17.25 3.33579 17.25 3.75V20.25C17.25 20.6642 16.9142 21 16.5 21H7.5C7.08579 21 6.75 20.6642 6.75 20.25V19H5.25V20.25C5.25 21.4926 6.25736 22.5 7.5 22.5H16.5C17.7426 22.5 18.75 21.4926 18.75 20.25V3.75C18.75 2.50736 17.7426 1.5 16.5 1.5H7.5C6.25736 1.5 5.25 2.50736 5.25 3.75V8H6.75V3.75C6.75 3.33579 7.08579 3 7.5 3H16.5Z"
        fill="black"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8.25 5.25C8.25 4.83579 8.58579 4.5 9 4.5H15C15.4142 4.5 15.75 4.83579 15.75 5.25C15.75 5.66421 15.4142 6 15 6H9C8.58579 6 8.25 5.66421 8.25 5.25Z"
        fill="black"
      />
      <path
        d="M10.3752 11V15.875C10.3752 15.9745 10.3357 16.0698 10.2654 16.1402C10.195 16.2105 10.0997 16.25 10.0002 16.25C9.90075 16.25 9.80537 16.2105 9.73504 16.1402C9.66472 16.0698 9.62521 15.9745 9.62521 15.875V11.9052L4.26552 17.2653C4.19516 17.3357 4.09972 17.3752 4.00021 17.3752C3.9007 17.3752 3.80526 17.3357 3.7349 17.2653C3.66453 17.1949 3.625 17.0995 3.625 17C3.625 16.9005 3.66453 16.8051 3.7349 16.7347L9.09505 11.375H5.12521C5.02575 11.375 4.93037 11.3355 4.86004 11.2652C4.78972 11.1948 4.75021 11.0995 4.75021 11C4.75021 10.9005 4.78972 10.8052 4.86004 10.7348C4.93037 10.6645 5.02575 10.625 5.12521 10.625H10.0002C10.0997 10.625 10.195 10.6645 10.2654 10.7348C10.3357 10.8052 10.3752 10.9005 10.3752 11Z"
        fill="black"
        stroke="black"
        strokeWidth="0.5"
      />
    </svg>
  );
}
