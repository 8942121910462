import Head from 'next/head';
import { useRouter } from 'next/router';
import useTranslation from 'next-translate/useTranslation';
import React from 'react';

interface RouteTitleProps {
  /** e.g.: {{extra}}, {{count}} */
  params?: Record<string, string>;
  /** e.g.: /kuro-point-histories:type=income, query should be {type: 'income'}. If { type: true } then the resulting translate query will be /kuro-point-histories:type */
  query?: Record<string, string | boolean>;
  disabled?: boolean;
}

/**
 * A component that sets the new document title using next/head's <Head />.
 * This used to be a hook (useRouteTitle) but document.title keeps on being reset by next/head whenever the <Head> component in _app.tsx rerenders, so we're using next/head to solve this issue.
 */
export default function RouteTitle(props: RouteTitleProps) {
  const { params, query, disabled } = props;
  const { t } = useTranslation();
  const { pathname } = useRouter();

  let queryString = query
    ? Object.entries(query)
        .map(([key, value]) => {
          if (typeof value === 'boolean') {
            if (value) {
              return key;
            }
            return '';
          }
          return `${key}=${value}`;
        })
        .filter((x) => Boolean(x))
        .join(',')
    : '';
  if (queryString) {
    queryString = ':' + queryString;
  }
  const title = t(`title:${pathname}${queryString}`, params, {
    default: '',
  });

  return disabled ? null : (
    <Head>
      {/* Key is required so we don't render the same HTML tag twice in <head> */}
      <title key="title">
        {/* We don't want things like {{name}} to appear in the title */}
        {title && !/{{.+}}/.test(title) ? `${title} | Kurosim` : 'Kurosim'}
      </title>
    </Head>
  );
}
