// This file stores base configuration or constants used for API-related stuff
import Config from 'common/config';

export const API_PREFIX = '/api/user';
export const KY_BASE_CONFIG = {
  prefixUrl: Config.apiEndpoint + API_PREFIX,
  timeout: 60000,
  headers: {
    Accept: 'application/json',
  },
};

/** These are constants that will be used by ky-hooks and auth/query. These needs to be synchronized to ensure that the auth flow is working properly. Honestly, I'd love to separate this from the query client since they're in different responsibility layers but let's go with this for now. */
export const GET_ME_KEY = ['getMe'];
