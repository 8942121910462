import crypto from 'crypto';
import invariant from 'invariant';

export function generateUniqueId(byteCount: number = 16) {
  return crypto.randomBytes(byteCount).toString('hex');
}

interface ColorTargets<T> {
  R?: T;
  G?: T;
  B?: T;
  all?: T;
}

export class GenColor {
  R: number;
  G: number;
  B: number;
  constructor(R: number, G: number, B: number) {
    this.R = Math.max(0, Math.min(255, R));
    this.G = Math.max(0, Math.min(255, G));
    this.B = Math.max(0, Math.min(255, B));
  }

  private static __defaultColorConstraints(
    constraint?: [number, number],
  ): [number, number] {
    return [
      constraint?.[0] ? Math.max(0, constraint?.[0]) : 0,
      constraint?.[1] ? Math.min(255, constraint?.[1]) : 255,
    ];
  }
  private static __generateColorFromConstraints(constraint?: [number, number]) {
    const min = constraint?.[0] ?? 0;
    const max = constraint?.[1] ?? 255;
    return Math.min(
      max,
      Math.max(min, Math.floor(min + Math.random() * (max - min))),
    );
  }
  static generate(constraints?: ColorTargets<[number, number]>) {
    return new GenColor(
      this.__generateColorFromConstraints(
        this.__defaultColorConstraints(constraints?.all ?? constraints?.R),
      ),
      this.__generateColorFromConstraints(
        this.__defaultColorConstraints(constraints?.all ?? constraints?.G),
      ),
      this.__generateColorFromConstraints(
        this.__defaultColorConstraints(constraints?.all ?? constraints?.B),
      ),
    );
  }
  static fromHex(hex: string) {
    if (hex.startsWith('#')) {
      hex = hex.substring(1);
    }
    invariant(
      hex.match(/[a-fA-F0-9]{6}/),
      'Hex should consist of six hexadecimal characters',
    );
    const R = parseInt(hex.substring(0, 2), 16);
    const G = parseInt(hex.substring(2, 4), 16);
    const B = parseInt(hex.substring(4, 6), 16);
    return new GenColor(R, G, B);
  }
  shift(shift: ColorTargets<number>) {
    const color = new GenColor(
      this.R + (shift?.R ?? shift?.all ?? 0),
      this.G + (shift?.G ?? shift?.all ?? 0),
      this.B + (shift?.B ?? shift?.all ?? 0),
    );
    return color;
  }
  hex() {
    return `#${this.R.toString(16).padStart(2, '0')}${this.G.toString(16).padStart(2, '0')}${this.B.toString(16).padStart(2, '0')}`;
  }
}
