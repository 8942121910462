import {
  UseMutationOptions,
  UseMutationResult,
  useMutation,
} from '@tanstack/react-query';
import { meKey } from 'api-hooks/auth';
import { IdInput } from 'api-hooks/common';
import { MutationFetchFunction } from 'common/api/fetch';
import { ApiMutationFunction } from 'common/api/fetch-types';
import { ApiError, ApiResult } from 'common/api/model';
import { queryClient } from 'common/api/query-client';

import {
  AccountModel,
  UpdateProfileMutationInput,
  UpdateSettingsMutationInput,
} from './model';
import { accountKey } from './query';

const ENDPOINT = 'me/account';

export function useUpdateSettings(
  options?: UseMutationOptions<
    ApiResult<AccountModel>,
    ApiError,
    UpdateSettingsMutationInput
  >,
): UseMutationResult<
  ApiResult<AccountModel>,
  ApiError,
  UpdateSettingsMutationInput
> {
  return useMutation<
    ApiResult<AccountModel>,
    ApiError,
    UpdateSettingsMutationInput
  >(
    async function (body) {
      return await MutationFetchFunction({
        method: 'PATCH',
        url: `${ENDPOINT}/settings`,
        body,
        classType: AccountModel,
      });
    },
    {
      onSuccess(data, variables, context) {
        queryClient.invalidateQueries(meKey.getMeKey);
      },
      ...options,
    },
  );
}

export function useUpdateProfile(
  options?: UseMutationOptions<
    ApiResult<AccountModel>,
    ApiError,
    UpdateProfileMutationInput
  >,
): UseMutationResult<
  ApiResult<AccountModel>,
  ApiError,
  UpdateProfileMutationInput
> {
  return useMutation<
    ApiResult<AccountModel>,
    ApiError,
    UpdateProfileMutationInput
  >(
    async function (body) {
      return await MutationFetchFunction({
        method: 'PATCH',
        url: `${ENDPOINT}/profile`,
        body,
        classType: AccountModel,
      });
    },
    {
      onSuccess(data, variables, context) {
        queryClient.invalidateQueries(meKey.getMeKey);
      },
      ...options,
    },
  );
}

const LINKED_DEVICE_ENDPOINT = 'me/sessions';
export const useLogoutLinkedDevice: ApiMutationFunction<
  IdInput,
  ApiResult<never>
> = (options) => {
  return useMutation(
    (body) => {
      return MutationFetchFunction({
        url: `${LINKED_DEVICE_ENDPOINT}/${body.id}/log-out`,
        method: 'PATCH',
      });
    },
    {
      onSuccess(data, variables, context) {
        queryClient.invalidateQueries(meKey.getMeKey);
        queryClient.invalidateQueries([accountKey.linkedDevicesKey]);
      },
      ...options,
    },
  );
};

export const useLogoutAllLinkedDevices: ApiMutationFunction<
  void,
  ApiResult<never>
> = (options) => {
  return useMutation(
    () => {
      return MutationFetchFunction({
        url: `${LINKED_DEVICE_ENDPOINT}/log-out-all`,
        method: 'PATCH',
      });
    },
    {
      onSuccess(data, variables, context) {
        queryClient.invalidateQueries(meKey.getMeKey);
        queryClient.invalidateQueries([accountKey.linkedDevicesKey]);
      },
      ...options,
    },
  );
};
