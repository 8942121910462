import { useGetMe, UserModel } from 'api-hooks/auth';
import notification from 'common/helpers/notification';
import { NavigationRoutes } from 'common/routes';
import { sendNativeMessage } from 'common/routes/bridge';
import { BridgeMessageType } from 'common/routes/bridge-types';
import useDetectDevice from 'hooks/use-detect-device';
import { useDelayedRedirect } from 'hooks/use-kurosim-navigation/navigator';
import useTranslation from 'next-translate/useTranslation';
import React from 'react';

import { SessionToken } from './token';
import useOauthSessionWeb from './use-oauth-session-web';

/*
24/06/2024: No need to store me data in localStorage anymore.
Potential privacy risk for users on shared computers and getMeStorage is not used anywhere.
We'll still need to sync whenever we receive new me data though.
*/
export function syncMeData(user: UserModel | undefined) {
  sendNativeMessage({
    type: BridgeMessageType.SyncMe,
    data: user ?? null,
  });
}

interface UseAuthProps {
  queryProps: Parameters<typeof useGetMe>[0];
}

export default function useAuth(props?: UseAuthProps) {
  const queryGetMe = useGetMe(props?.queryProps);
  const { data, error, isFetching } = queryGetMe;

  const isAuthenticated = !!data;
  const isRedirectLogin = !isFetching && error?.statusCode === 401;

  React.useEffect(() => {
    if (isFetching) return;

    // Sync data with Native
    if (error?.statusCode === 401) {
      syncMeData(undefined);
    } else if (data?.data) {
      syncMeData(data.data);
    }
  }, [data?.data, error, isFetching]);

  return {
    isAuthenticated,
    isRedirectLogin,
    ...queryGetMe,
  };
}

// Used in public routes which needs to redirect to login screen
export function useLoginIfUnauthenticated(fn: () => Promise<void>) {
  const { t } = useTranslation();
  const redirect = useDelayedRedirect({
    message: t('common:redirecting_to_login_screen'),
    navigate({ push }) {
      push(NavigationRoutes.Login);
    },
  });
  return async () => {
    try {
      await fn();
    } catch (e) {
      console.error(e);
      if (e.message) {
        notification.error({ message: e.message });
      }
      if (e.statusCode === 401) {
        redirect();
      }
    }
  };
}

export function HandleRefreshOauthComponent() {
  const [isInitiate, setIsInititate] = React.useState(false);
  const { onGetSessionToken, session } = useOauthSessionWeb();
  const { isKurosimApp } = useDetectDevice();

  React.useEffect(() => {
    async function exec() {
      try {
        await onGetSessionToken();
      } catch (e) {
        console.error(e);
      }
    }
    // has access token
    const { isRefreshTokenExpired, isAccessTokenExpired } =
      SessionToken.checkTokenExpiration(SessionToken.get());

    if (
      typeof window === 'undefined' ||
      isRefreshTokenExpired ||
      isAccessTokenExpired ||
      !session ||
      isInitiate ||
      isKurosimApp
    ) {
      return;
    }

    exec();
    setIsInititate(true);
  }, [isInitiate, isKurosimApp, onGetSessionToken, session]);

  return null;
}

export const CurrentUserContext = React.createContext<UserModel | null>(null);
