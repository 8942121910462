import { useUpdateSettings } from 'api-hooks/account';
import { getEnumsKey } from 'api-hooks/common';
import { queryClient } from 'common/api/query-client';
import { LocalStorageKeys } from 'common/constants/browser-storage-keys';
import notification from 'common/helpers/notification';
import { NavigationRoutes } from 'common/routes';
import useNativeBridge from 'common/routes/bridge';
import { BridgeMessageType } from 'common/routes/bridge-types';
import { useRouter } from 'next/router';
import React from 'react';

import useAuth from '../common/auth/use-auth';

export type LanguageType = 'en' | 'id';

export function getLanguageStorage(): LanguageType {
  if (typeof window === 'undefined') return 'en';
  const result = localStorage.getItem(
    LocalStorageKeys.Language,
  ) as LanguageType | null;
  return result || 'en';
}

export function setLanguageStorage(value: LanguageType) {
  if (typeof window === 'undefined') return;
  localStorage.setItem(LocalStorageKeys.Language, value);
}

interface LanguageContextProps {
  language: LanguageType;
  handleLanguage: (value: LanguageType) => void;
}

export const LanguageContext = React.createContext<LanguageContextProps>({
  language: 'en',
  handleLanguage: () => {},
});

export function LanguageProvider({ children }) {
  const [language, setLanguage] = React.useState<LanguageType>('en');
  const { data: me } = useAuth();
  const { mutateAsync: updateSettingAsync } = useUpdateSettings();
  const { asPath, replace, pathname, locale } = useRouter();
  const send = useNativeBridge({});

  const handleLanguage = React.useCallback(
    async (value: LanguageType) => {
      setLanguage(value);
      setLanguageStorage(value);
      replace(asPath, asPath, {
        locale: value,
      });
      queryClient.invalidateQueries(getEnumsKey());
      send?.({
        data: value,
        type: BridgeMessageType.language,
      });
      if (!me) return;
      // api fetching
      try {
        const result = await updateSettingAsync({
          language: value,
        });
        result.message &&
          notification.success({
            message: result.message,
          });
      } catch (e) {
        console.error(e);
        notification.error({
          message: e.message,
        });
      }
    },
    [asPath, me, replace, send, updateSettingAsync],
  );

  //Change Language from URL (Navigate from Kurosim Site)
  React.useEffect(() => {
    if (typeof window === 'undefined') return;
    if (!!locale && locale !== getLanguageStorage()) {
      handleLanguage(locale as any);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  //sync
  React.useEffect(() => {
    if (typeof window === 'undefined') return;
    if (pathname === NavigationRoutes.NotFound) return;
    const storageLanguage = getLanguageStorage();
    // Only update the path if the languaghe is different.
    if (locale === storageLanguage) return;
    setLanguage(storageLanguage);
    replace(asPath, asPath, {
      locale: storageLanguage,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [asPath, pathname]);

  return (
    <LanguageContext.Provider value={{ language, handleLanguage }}>
      {children}
    </LanguageContext.Provider>
  );
}

export default function useLanguage() {
  const context = React.useContext(LanguageContext);
  return context;
}
