import { useGetMe, usePatchSubscribe } from 'api-hooks/auth';
import exportEnv from 'common/config';
import useNativeBridge from 'common/routes/bridge';
import { BridgeMessageType } from 'common/routes/bridge-types';
import * as React from 'react';
import OneSignal from 'react-onesignal';

import useDetectDevice from './use-detect-device';

export default function PushNotificationHandler() {
  const [init, setInit] = React.useState(false);
  const [prevUserId, setPrevUserId] = React.useState<string | undefined>(
    undefined,
  );
  const { isKurosimApp } = useDetectDevice();
  const send = useNativeBridge({});

  const { data } = useGetMe();
  const userId = data?.data?.id;
  const isPushNotification = data?.data?.settings?.pushNotification;

  const { mutateAsync: subscribe } = usePatchSubscribe();

  const onSubscribe = React.useCallback(
    async (subsId: string) => {
      try {
        await subscribe({ subscriptionId: subsId });
      } catch (e) {
        console.error(e);
        // There's no need to show oneSignal issues to user
        // if (e?.message) {
        //   notification.error({ message: e.message });
        // }
      }
    },
    [subscribe],
  );

  // Initialize
  React.useEffect(() => {
    async function exec() {
      try {
        await OneSignal.init({
          appId: exportEnv.onesignalAppId,
          allowLocalhostAsSecureOrigin: true,
        });

        await OneSignal.Slidedown.promptPush();
        setInit(true);
      } catch (e) {
        console.error(e);
      }
    }

    if (typeof window === 'undefined') return;
    !init && exec();
  }, [init]);

  // Login
  React.useEffect(() => {
    async function exec() {
      try {
        await OneSignal.login(userId!);

        if (OneSignal?.User?.PushSubscription?.id) {
          await onSubscribe(OneSignal.User.PushSubscription.id);
        }
      } catch (e) {
        console.error(e);
      }
    }

    if (typeof window === 'undefined') return;
    !!userId && !!isPushNotification && exec();
  }, [isPushNotification, onSubscribe, userId]);

  // Logout
  React.useEffect(() => {
    async function exec() {
      if (isKurosimApp) {
        send?.({
          data: null,
          type: BridgeMessageType.Logout,
        });
      } else {
        OneSignal.logout();
      }
    }

    if (typeof window === 'undefined') return;
    !userId && !!isPushNotification && exec();
  }, [isKurosimApp, isPushNotification, send, userId]);

  // Set Previous userId
  React.useEffect(() => {
    setPrevUserId(userId);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // Sync Webapp
  React.useEffect(() => {
    async function exec() {
      if (prevUserId) {
        if (prevUserId !== userId) {
          await OneSignal.logout();
          await OneSignal.login(userId!);
          setPrevUserId(userId);
        }
      } else {
        await OneSignal.login(userId!);
        setPrevUserId(userId);
      }
    }
    if (typeof window === 'undefined') return;
    !!userId && exec();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userId]);

  // Sync Native
  React.useEffect(() => {
    async function exec() {
      await send?.({
        type: BridgeMessageType.SyncOneSignal,
        data: {
          userId: userId!,
        },
      });
    }

    if (typeof window === 'undefined') return;
    !!userId && !!isKurosimApp && exec();
  }, [isKurosimApp, send, userId]);

  return null;
}

export function OneSignalLogout() {
  OneSignal.logout();

  return null;
}
