import { Loader } from '@mantine/core';
import classNames from 'classnames';
import {
  CustomizationUnit,
  applyCustomization,
} from 'common/repositories/customization';
import colors from 'common/styles/colors';
import { useIsSmallScreen } from 'hooks/use-is-small-screen';
import useGetParentRef, {
  ParentRefType,
  useIsMounted,
} from 'hooks/use-parent-ref';
import * as React from 'react';
import { createPortal } from 'react-dom';
import structuralStyles from 'styles/layout.css';

import { loadingViewStyles } from './style.css';

export interface LoadingViewComponentProps {
  children?: React.ReactNode;
  // Whether the Loading Component should always render as a fixed element rather fill its container even if it's inside a LoadingViewComponentPortal
  fixed?: boolean;
  customization?: {
    root?: CustomizationUnit<React.ComponentProps<'div'>>;
    loaderContainer?: CustomizationUnit<React.ComponentProps<'div'>>;
    loader?: React.ComponentProps<typeof Loader>;
  };
}

export default function LoadingViewComponent(props: LoadingViewComponentProps) {
  const { children, fixed, customization } = props;

  const portal = useGetParentRef(ParentRefType.Loading);
  const isMounted = useIsMounted(true);
  const renderInPortal = portal && portal.current && !fixed;
  const isMobile = useIsSmallScreen();

  const element = (
    <div
      {...applyCustomization(
        {
          className: classNames(
            loadingViewStyles.container({
              fixed: !renderInPortal,
            }),
            structuralStyles.flexbox({
              justify: 'center',
              direction: 'column',
            }),
          ),
        },
        [customization?.root],
      )}
    >
      {children ?? (
        <div
          {...applyCustomization(
            { className: loadingViewStyles.loaderContainer() },
            [customization?.loaderContainer],
          )}
        >
          <Loader
            size={isMobile ? 16 : 24}
            c={colors.foregroundPrimary}
            {...customization?.loader}
          />
        </div>
      )}
    </div>
  );

  if (!isMounted) return null;

  if (renderInPortal && portal.current) {
    return createPortal(element, portal.current);
  } else {
    return element;
  }
}
