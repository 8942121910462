import { plainToInstance } from 'class-transformer';
import { spliceElements } from 'common/utils/iterable';
import isObjectLike from 'lodash/isObjectLike';
import mergeWith from 'lodash/mergeWith';

export enum CustomValueBehavior {
  /**
   * If string => concatenate both strings with a space in the middle
   * If array => mutate the original array by appending the value
   * If object => combine both objects
   * If not any of that => overwrite
   */
  Combine,
  /** Always replace old value with new value */
  Overwrite,
  /** Caller handles how to calculate the value */
  Custom,
}

function combineRules<T>(result: T, value: T) {
  if (typeof value === 'string') {
    return `${result} ${value}` as typeof result;
  } else if (Array.isArray(value)) {
    return [...(result as any[]), value];
  } else if (isObjectLike(value)) {
    return { ...value, ...result };
  } else {
    return value;
  }
}

function resolveCustomValue<T>(initial: T, value: CustomValue<T>) {
  let result: any = initial;
  switch (value.behavior) {
    case CustomValueBehavior.Combine:
      result = combineRules(result, value.value);
      break;
    case CustomValueBehavior.Overwrite:
      result = value.value;
      break;
    case CustomValueBehavior.Custom:
      if (value?.resolver) {
        result = value.resolver(result);
      }
      break;
  }
  return result;
}

class SmartCustomValue<TInput, TTarget> {
  // Gets input and the current value, transforms that into a new value
  factory: (input: TInput, value: TTarget) => TTarget | CustomValue<TTarget>;
}

export class CustomValue<T> {
  behavior: CustomValueBehavior;
  value: T;
  resolver?: (other: T) => T;

  static combine<T>(value: T): CustomValue<T> {
    return plainToInstance<CustomValue<T>, unknown>(CustomValue, {
      value,
      behavior: CustomValueBehavior.Combine,
    });
  }
  static combineAll<T extends object>(value: T): CustomizationUnit<T> {
    const result: CustomizationUnit<T> = {} as any;
    for (const key of Object.keys(value)) {
      result[key] = this.combine(value[key]);
    }
    return result;
  }
  static overwrite<T>(value: T): CustomValue<T> {
    return plainToInstance<CustomValue<T>, unknown>(CustomValue, {
      value,
      behavior: CustomValueBehavior.Overwrite,
    });
  }
  static overwriteAll<T extends object>(value: T): CustomizationUnit<T> {
    const result: CustomizationUnit<T> = {} as any;
    for (const key of Object.keys(value)) {
      result[key] = this.overwrite(value[key]);
    }
    return result;
  }
  static custom<T>(resolver: (other: T) => T): CustomValue<T> {
    return plainToInstance<CustomValue<T>, unknown>(CustomValue, {
      behavior: CustomValueBehavior.Custom,
      resolver,
    });
  }
  static lazy<TInput, TTarget>(
    factory: (input: TInput, target: TTarget) => TTarget | CustomValue<TTarget>,
  ): SmartCustomValue<TInput, TTarget> {
    return plainToInstance<SmartCustomValue<TInput, TTarget>, unknown>(
      SmartCustomValue,
      {
        factory,
      },
    );
  }

  static spliceClass(remove: string | string[], append: string | string[]) {
    return plainToInstance<CustomValue<string>, unknown>(CustomValue, {
      behavior: CustomValueBehavior.Custom,
      resolver(input) {
        return spliceElements(input?.split?.(' ') ?? [], remove, append).join(
          ' ',
        );
      },
    });
  }

  static resolve<T>(initial: T, value: T | CustomValue<T>): T {
    if (!(value instanceof CustomValue)) {
      return value;
    }
    return resolveCustomValue(initial, value);
  }
  static resolveSmart<TInput, TTarget>(
    params: TInput,
    initial: TTarget,
    value: TTarget | CustomValue<TTarget> | SmartCustomValue<TInput, TTarget>,
  ): TTarget {
    const result =
      value instanceof SmartCustomValue
        ? value.factory(params, initial)
        : value;
    if (result instanceof CustomValue) {
      return resolveCustomValue(initial, result);
    }
    return result;
  }
}

/** Every prop can be inserted verbatim or with a specific behavior. Alternatively, this can be a function that builds the entire props object */
export type CustomizationUnit<T extends object> =
  | {
      [key in keyof T]?: CustomValue<T[key]>;
    }
  | ((value: T) => T);

/** Same as above, but with input */
export type SmartCustomizationUnit<TInput, TTarget extends object> =
  | {
      [key in keyof TTarget]?:
        | CustomValue<TTarget[key]>
        | SmartCustomValue<TInput, TTarget[key]>;
    }
  | ((params: TInput, value: TTarget) => TTarget);

/** Resolves the available customizations. Initial is the minimal prop object (with required stuff) */
export function applyCustomization<T extends object>(
  initial: T,
  units: (CustomizationUnit<T> | undefined)[],
): T {
  let result = { ...initial };
  for (const unit of units) {
    if (unit == null) continue;
    // If CustomizationUnit is a function
    if (typeof unit === 'function') {
      result = unit(result);
      continue;
    }
    for (const key of Object.keys(unit) as (keyof typeof unit)[]) {
      const property = unit[key];
      if (property instanceof CustomValue) {
        // For every property, resolve it with the available property in result and then store it.
        result[key] = CustomValue.resolve(result[key], property) as any;
      }
    }
  }
  return result;
}

export function applySmartCustomization<TInput, TTarget extends object>(
  params: TInput,
  initial: TTarget,
  units: (SmartCustomizationUnit<TInput, TTarget> | undefined)[],
): TTarget {
  let result = { ...initial };
  for (const unit of units) {
    if (unit == null) continue;
    if (typeof unit === 'function') {
      result = unit(params, result);
      continue;
    }
    for (const key of Object.keys(unit) as (keyof typeof unit)[]) {
      const property = unit[key];
      if (property instanceof SmartCustomValue) {
        result[key] = CustomValue.resolveSmart(params, result[key], property);
      } else if (property instanceof CustomValue) {
        result[key] = CustomValue.resolve(result[key], property);
      }
    }
  }
  return result;
}

function mergeCustomizationRules(a, b, key) {
  const isACustomValue =
    a instanceof CustomValue || a instanceof SmartCustomValue;
  const isBCustomValue =
    b instanceof CustomValue || b instanceof SmartCustomValue;
  if (isACustomValue && isBCustomValue) {
    if (a instanceof CustomValue && b instanceof CustomValue) {
      return CustomValue.custom((value) => {
        return CustomValue.resolve(CustomValue.resolve(value, a), b);
      });
    }
    return CustomValue.lazy((data, value) => {
      const first =
        a instanceof SmartCustomValue
          ? CustomValue.resolveSmart(data, value, a)
          : CustomValue.resolve(value, a);
      const second =
        b instanceof SmartCustomValue
          ? CustomValue.resolveSmart(data, first, b)
          : CustomValue.resolve(first, b);
      return second;
    });
  } else if (isACustomValue) {
    return a;
  } else if (isBCustomValue) {
    return b;
  } else {
    return undefined;
  }
}

export function mergeCustomization<T>(first: T, ...customizations: T[]): T {
  let result = mergeWith({}, first, mergeCustomizationRules);
  for (const custom of customizations) {
    result = mergeWith(result, custom, mergeCustomizationRules);
  }
  return result;
}
