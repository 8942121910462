export default function AddShoppingCartSimple(
  props: React.SVGProps<SVGSVGElement>,
) {
  const { color = 'black' } = props;
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M9 20.25C9 20.5467 8.91203 20.8367 8.7472 21.0834C8.58238 21.33 8.34811 21.5223 8.07403 21.6358C7.79994 21.7494 7.49834 21.7791 7.20736 21.7212C6.91639 21.6633 6.64912 21.5204 6.43934 21.3107C6.22956 21.1009 6.0867 20.8336 6.02882 20.5426C5.97094 20.2517 6.00065 19.9501 6.11418 19.676C6.22771 19.4019 6.41997 19.1676 6.66665 19.0028C6.91332 18.838 7.20333 18.75 7.5 18.75C7.89782 18.75 8.27936 18.908 8.56066 19.1893C8.84196 19.4706 9 19.8522 9 20.25ZM17.25 18.75C16.9533 18.75 16.6633 18.838 16.4166 19.0028C16.17 19.1676 15.9777 19.4019 15.8642 19.676C15.7506 19.9501 15.7209 20.2517 15.7788 20.5426C15.8367 20.8336 15.9796 21.1009 16.1893 21.3107C16.3991 21.5204 16.6664 21.6633 16.9574 21.7212C17.2483 21.7791 17.5499 21.7494 17.824 21.6358C18.0981 21.5223 18.3324 21.33 18.4972 21.0834C18.662 20.8367 18.75 20.5467 18.75 20.25C18.75 19.8522 18.592 19.4706 18.3107 19.1893C18.0294 18.908 17.6478 18.75 17.25 18.75ZM21.7172 6.97031L19.0425 15.6619C18.9024 16.1226 18.6175 16.5259 18.2301 16.812C17.8427 17.0981 17.3734 17.2517 16.8919 17.25H7.88156C7.3931 17.2482 6.91837 17.0882 6.52848 16.7939C6.13858 16.4997 5.85449 16.087 5.71875 15.6178L2.32687 3.75H0.75C0.551088 3.75 0.360322 3.67098 0.21967 3.53033C0.0790176 3.38968 0 3.19891 0 3C0 2.80109 0.0790176 2.61032 0.21967 2.46967C0.360322 2.32902 0.551088 2.25 0.75 2.25H2.32687C2.65257 2.25108 2.96916 2.35761 3.22925 2.55365C3.48934 2.74969 3.67895 3.0247 3.76969 3.3375L4.53 6H21C21.1174 5.99996 21.2331 6.02746 21.3379 6.0803C21.4427 6.13313 21.5336 6.20982 21.6034 6.30421C21.6732 6.39859 21.7198 6.50803 21.7396 6.62372C21.7593 6.73941 21.7517 6.85812 21.7172 6.97031ZM19.9847 7.5H4.95844L7.16062 15.2063C7.20543 15.3629 7.30002 15.5007 7.43009 15.5988C7.56016 15.6969 7.71864 15.75 7.88156 15.75H16.8919C17.0524 15.7501 17.2086 15.6986 17.3377 15.6033C17.4668 15.508 17.5619 15.3737 17.6091 15.2203L19.9847 7.5Z"
        fill={color}
      />
      <path
        d="M15 11.5C15 11.5553 14.9781 11.6082 14.939 11.6473C14.8999 11.6864 14.8469 11.7083 14.7917 11.7083H12.7083V13.7917C12.7083 13.8469 12.6864 13.8999 12.6473 13.939C12.6082 13.9781 12.5553 14 12.5 14C12.4447 14 12.3918 13.9781 12.3527 13.939C12.3136 13.8999 12.2917 13.8469 12.2917 13.7917V11.7083H10.2083C10.1531 11.7083 10.1001 11.6864 10.061 11.6473C10.0219 11.6082 10 11.5553 10 11.5C10 11.4447 10.0219 11.3918 10.061 11.3527C10.1001 11.3136 10.1531 11.2917 10.2083 11.2917H12.2917V9.20833C12.2917 9.15308 12.3136 9.10009 12.3527 9.06102C12.3918 9.02195 12.4447 9 12.5 9C12.5553 9 12.6082 9.02195 12.6473 9.06102C12.6864 9.10009 12.7083 9.15308 12.7083 9.20833V11.2917H14.7917C14.8469 11.2917 14.8999 11.3136 14.939 11.3527C14.9781 11.3918 15 11.4447 15 11.5Z"
        fill={color}
        stroke={color}
        strokeWidth="0.8"
      />
    </svg>
  );
}
