import notification from 'common/helpers/notification';
import useNativeBridge from 'common/routes/bridge';
import {
  BridgeMessageType,
  PermissionStatusEnum,
} from 'common/routes/bridge-types';
import React from 'react';

export interface NotificationContextProps {
  isGranted: boolean;
  onSendPermissionRequest: () => Promise<boolean>;
}

export type NotificationPermissionNativeType = {
  type: 'notification-feedback';
  result: {
    status: 'granted' | 'denied' | 'blocked';
  };
};

export async function checkPermissionBrowser() {
  if (!('Notification' in window)) {
    return false;
  }

  const checkDenied = () => {
    if (Notification.permission === 'denied') {
      return false;
    }
  };

  if (Notification.permission === 'granted') {
    return true;
  }

  const permission = await Notification.requestPermission();
  checkDenied();
  return permission === 'granted';
}

export const NotificationPermissionContext =
  React.createContext<NotificationContextProps>({
    isGranted: false,
    async onSendPermissionRequest() {
      return false;
    },
  });

export function checkPermissionNative(status: PermissionStatusEnum) {
  switch (status) {
    case PermissionStatusEnum.GRANTED:
    case PermissionStatusEnum.LIMITED:
      return true;
    case PermissionStatusEnum.UNAVAILABLE:
    case PermissionStatusEnum.BLOCKED:
    case PermissionStatusEnum.DENIED:
      return false;
  }
}

export function NotificationProvider({ children }) {
  const [isGranted, setIsGranted] = React.useState(false);
  const [isGrantedNative, setIsGrantedNative] = React.useState(false);

  const handlePermissionWebview = React.useCallback(
    (status: PermissionStatusEnum) => {
      try {
        const granted = checkPermissionNative(status);
        setIsGrantedNative(granted);
        return granted;
      } catch (e) {
        console.error(e);
        notification.error({
          message: e.message,
        });
        return false;
      }
    },
    [],
  );

  // send
  const send = useNativeBridge({
    handlers: {
      [BridgeMessageType.NotificationResponse]: (message, context) => {
        handlePermissionWebview(message.data);
      },
    },
  });

  const onSendPermissionRequest = async () => {
    try {
      if ('ReactNativeWebView' in window) {
        send?.({
          type: BridgeMessageType.NotificationRequest,
          data: null,
        });
      }
      const isGranted = await checkPermissionBrowser();
      setIsGranted(isGranted);
      return isGranted;
    } catch (e) {
      console.error(e);
      notification.error({
        message: e.message,
      });
      return false;
    }
  };

  return (
    <NotificationPermissionContext.Provider
      value={{
        isGranted: isGranted || isGrantedNative,
        onSendPermissionRequest,
      }}
    >
      {children}
    </NotificationPermissionContext.Provider>
  );
}

export default function useGetNotificationPermission() {
  const context = React.useContext(NotificationPermissionContext);
  return context;
}
