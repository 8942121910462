import { CustomValue } from 'common/repositories/customization';
import React from 'react';

import { TextLinkStyles } from './styles.css';
import Text, { TextProps } from '../text';

interface Props {
  onClick?: React.MouseEventHandler<HTMLButtonElement>;
  textProps?: Omit<TextProps, 'className'> & {
    className?: CustomValue<string>;
  };
  buttonProps?: React.ComponentProps<'button'>;
  children?: React.ReactNode;
}

export default function TextLink(props: Props) {
  const { onClick, textProps, buttonProps, children } = props;
  return (
    <button type="button" onClick={onClick} {...buttonProps}>
      <Text
        td="underline"
        textColor="foregroundPrimary"
        p={0}
        m={0}
        span
        {...textProps}
        className={
          textProps?.className
            ? CustomValue.resolve(TextLinkStyles.hover, textProps.className)
            : TextLinkStyles.hover
        }
      >
        {children}
      </Text>
    </button>
  );
}
